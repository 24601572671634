import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  onClose: () => void;
}

const ConfirmExitTestDialog = ({ onClose }: Props) => {
  const navigate = useNavigate();
  return (
    <Dialog open={true} fullWidth maxWidth={"xs"}>
      <DialogTitle>Are you sure you want to exit the test?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          All of your progress will be disregard
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" disableElevation onClick={onClose}>
          No
        </Button>
        <Button
          variant="contained"
          disableElevation
          onClick={() => navigate("/paths")}
          sx={{
            backgroundColor: "#2e69ff",
            "&:hover": {
              backgroundColor: "#2e69ff",
            },
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmExitTestDialog;
