import React, { useEffect, useState, useRef } from "react";
import styles from "./Animate.module.scss";

interface Prop {
  type: string
  children: any
  showOnScroll?: boolean
  delay?: number | string
} 

interface AnimationTypes {
  "fade-down": string
  "fade-up": string
  "fade-right": string
  "fade-left": string
  [key: string]: string
}

const animationTypes: AnimationTypes = {
  "fade-down": "FadeDown",
  "fade-up": "FadeUp",
  "fade-right": "FadeRight",
  "fade-left": "FadeLeft",
}

const Animate: React.FC<Prop> = ({ 
  type, 
  children,
  delay, 
  showOnScroll 
}) => {
  const container = useRef<any>();
  const [currentClass, setCurrentClass] = useState<string>("DefaultPosition");

  useEffect(()=> {
    if(showOnScroll) {
      window.addEventListener("scroll", (e) => {
        setAnimation()
      });
    } else {
      setAnimation()
    }
  }, []);

  const setAnimation = () => {
    const currentEl = container.current?.getBoundingClientRect()?.top
    if (currentEl >= 200 && currentEl <= 500) { // update animation classes
      setCurrentClass(animationTypes[type])
    } else if (currentEl < 200) { // revert back to the old position
      setCurrentClass("FixedPosition");
    } else if (currentEl > 500) {
      setCurrentClass("DefaultPosition");
    }
  }

  return (
    <div 
      ref={container}
      className={styles[currentClass]}
      style={{
        transitionDelay: `${delay}s`
      }}
    >
      {children}
    </div>
  )
}

Animate.defaultProps = {
  showOnScroll: true,
  delay: 0
}

export default Animate;