export const initialState = {
  profileDetails: {
    userId: null,
    profileId: null,
    about: null,
    intro: null,
    availabilityStatus: null,
    experience: [],
    hourlyRate: null,
    technologies: [],
    socialMedia: [],
    profileImage: {
      originalImage: null,
      thumbnailImage: null,
    },
    email: null,
    firstName: null,
    lastName: null,
  },
};

const ProfileReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SET_PROFILE_DETAILS":
      return {
        ...state,
        profileDetails: (state.profileDetails = payload),
      };
    default:
      throw new Error("No case type found");
  }
};

export default ProfileReducer;
