import React, { useState } from "react";
import styles from "./MobileNavigation.module.scss";
import useDisplay from "contexts/Display";
import { Avatar, Button, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ThemeWrapper from "components/ThemeWrapper";
import { ReactComponent as MainLogo } from "assets/mainLogo.svg"
import { Box, Typography } from '@mui/material';
import useUser from 'contexts/User';

const MobileNavigation: React.FC<any> = ({
  onClose
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { displayMode } = useDisplay();
  const {
    userDetails,
    logoutUser,
  } = useUser();

  // @ts-ignore
  const accessToken = localStorage.getItem("accessToken");

  const handleLogout = async () => {
    try {
      const res = await logoutUser();
      if (res.data.data.success) {
        navigate('/');
      }
    } catch (err) {
      console.log(err);
    }
  };
    
  return (
    <Box className={styles.MobileNavigation}>
      <ThemeWrapper>
        <Box
          sx={{
            cursor: 'pointer',
            display: 'flex'
          }}
          onClick={() => {
            onClose()
            navigate('/')
          }}
        >
          <Box
            sx={{
              width: '30px',
              mt: -0.5  ,
              height: '30px',
              pl: 0.8,
              backgroundImage: 'radial-gradient(145.05% 100% at 50% 0,#1d2b41 0,#0b1627 57.38%,#142133 88.16%)',
              boxShadow: '0 5px 12px rgba(0,0,0,.12)',
              pr: 0.7,
              borderRadius: '12px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <MainLogo />
          </Box>
          <Box sx={{ ml: 1.5 }}>
            <Typography variant="h5" fontSize={16}>OneGuru</Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Box>
            <Button 
              onClick={() => {
                onClose();
                navigate('/');
              }}
              sx={{ 
                borderRadius: '0px',
                textAlign: 'left',
                justifyContent: 'left',
                px: 0
              }}
            >
              Home
            </Button>
          </Box>
          <Box sx={{ mt: 1 }}>
            <Button 
              sx={{ 
                borderRadius: '0px',
                textAlign: 'left',
                justifyContent: 'left',
                px: 0
              }}
              onClick={() => {
                onClose();
                navigate('/pricing');
              }}
            >
              Pricing
            </Button>
          </Box>
        </Box>
        {!accessToken ? (
          <>
            <Box sx={{ mt: 1 }}>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => navigate('/login')}
                sx={{
                  width: '100%'
                }}
              >
                <span style={{color: displayMode === "DARK" ? "#FFF": "#000"}}>Sign In</span>
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ mt: 1 }}>
              <Button 
                sx={{ 
                  borderRadius: '0px',
                  textAlign: 'left',
                  justifyContent: 'left',
                  px: 0
                }}
                onClick={() => {
                  onClose();
                  handleLogout();
                }}
              >
                Log Out
              </Button>
            </Box>
            <ListItem 
              sx={{ px: 0 }} 
              onClick={() => {
                onClose();
                navigate('/coming-soon');
              }}
            >
              <ListItemAvatar sx={{ minWidth: "45px" }}>
                <Avatar
                  //@ts-ignore
                  src={localStorage.getItem("profileImage")}
                  style={{ 
                    borderRadius: '100%',
                    height: 35,
                    width: 35
                  }}
                />
              </ListItemAvatar>
              <ListItemText>
                {/* @ts-ignore */}
                {localStorage.getItem("firstName")}
              </ListItemText>
            </ListItem>
          </>
        )}
        <Box sx={{ mt: 2 }}>
          <Button
            color="primary"
            variant="contained"
            sx={{
              width: '100%'
            }}
            onClick={() => navigate('/question/1')}
          >
            Take the test
          </Button>
        </Box>
      </ThemeWrapper>
    </Box>
  )
}

export default MobileNavigation;