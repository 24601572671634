export const initialState = {
  correctAnswers: 0,
}

const TestReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SET_CORRECT_ANSWERS":
      return {
        ...state,
        correctAnswers: state.correctAnswers = payload
      }
    default:
      throw new Error('No case type found')
  }
}

export default TestReducer