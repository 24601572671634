import React, { FC, useState } from "react";
import { 
  Dialog, 
  Box, 
  Typography,
  DialogContent, 
  Divider, 
  DialogContentText, 
  DialogTitle, 
  DialogActions, 
  IconButton,
  Button
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Choice, Question } from "utils/apiTypes";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
interface Props {
  onClose: () => void;
  title: string;
  question: Question;
  description: string;
  imageUrl: string;
};

const TextDialog = ({
  title,
  description,
  question,
  imageUrl,
  onClose
}: Props) => {
  const navigate = useNavigate();
  return (
    <Dialog 
      open={true}
      fullWidth 
      maxWidth={"xs"}
      onClose={onClose}
      PaperProps={{
        style: {
          maxWidth: "1500px",
          paddingTop: '30px',
          paddingBottom: '30px'
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          px: 4
        }}
      >
        <Box></Box>
        <IconButton onClick={onClose}>
          <CloseIcon fontSize="large" />
        </IconButton>
      </Box>
      <DialogContent>
        {/* Explanation */}
        <Box 
          sx={{ 
            display: 'flex',
            width: '65%', 
            mx: 'auto',
            overflow: 'hidden'
          }}
        >
          {question.choices.map((choice: Choice, key: number) => (
            <Box key={key}>
              <Box>
                <Box
                  sx={{
                    width: '400px',
                    borderBottom: '5px solid #FFF',
                    boxShadow: '0 15px 40px rgba(0,0,0,0.12)',
                    overflowY: 'auto',
                    mx: 'auto',
                    height: '160px'
                  }}
                >
                  {/* wrong or right icon */}
                  <Box 
                    sx={{ 
                      mt: -2,
                      ml: -1,
                      position: 'absolute',
                      overflow: 'auto',
                      backgroundColor: question.correctAnswer === choice.item ? 'green.300' : 'red.300',
                      borderRadius: '100%',
                      width: '30px',
                      height: '30px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    {(question.correctAnswer === choice.item) ? (
                      <CheckIcon 
                        sx={{ 
                          color: 'green.700',
                          fontWeight: 800
                        }} 
                      />
                    ) : (
                      <CloseIcon 
                        sx={{
                          color: 'red.700',
                          fontWeight: 800
                        }}
                      />
                    )}
                  </Box>
                  {/* explanation */}
                  <Box sx={{ py: 2, px: 2.5 }}>
                    <Typography
                      variant="body2" 
                      sx={{
                        fontWeight: 400,
                        fontSize: 14.8,
                        color: 'text.secondary'
                      }}
                      dangerouslySetInnerHTML={{ __html: choice.explanation }}
                    >
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: 0,
                    height: 0,
                    mx: 'auto', 
                    zIndex: 8,
                    position: 'relative',
                    borderLeft: '15px solid transparent',
                    borderRight: '15px solid transparent',
                    borderTop: '15px solid #FFFF'
                  }}
                ></Box>
              </Box>
              <Box 
                sx={{
                  flexDirection: question.rows === 1 ? 'row' : 'column'
                }}
              >
                <Box
                  sx={{
                    width: {
                      xs: '90%',
                      sm: '90%',
                      md: '100%',
                      lg: '100%'
                    },
                    mx: 'auto'
                  }}
                >
                  <img
                    src={choice.url}
                    style={{
                      width: choice.width || '100%',
                      margin: '0 auto'
                    }}
                  />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TextDialog;