export const initialState = {
  userDetails: {
    user: {
      accessToken: ''
    },
    profile: {
      _id: '',
      userId: '',
      profileImage: {
        thumbnailImage: '',
        originalImage: ''
      }
    }
  },
  loadingPage: null
}

const UserReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SET_USER_DETAILS":
      return {
        ...state,
        userDetails: state.userDetails = payload
      }
    case "EMPTY_USER_DETAILS":
      return {
        ...state,
        userDetails: state.userDetails = {
          user: {
            accessToken: ''
          },
          profile: {
            _id: '',
            userId: '',
            profileImage: {
              thumbnailImage: '',
              originalImage: ''
            }
          }
        }
      }
    case "SET_LOADING_PAGE":
      return {
        ...state,
        loadingPage: state.loadingPage = payload
      }
    default:
      throw new Error('No case type found')
  }
}

export default UserReducer