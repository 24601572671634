import useProfile from "contexts/Profile";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { Path } from "utils/apiTypes";

export default function useSaveQuestion() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { addProfilePath, getProfile } = useProfile();
  const [enabledProfileFetch, setEnabledProfileFetch] =
    useState<boolean>(false);

  const paramVal = params.label || searchParams.get("from");

  // @ts-ignore
  const paths = JSON.parse(localStorage.getItem("paths"));
  let path = paths?.filter((path: Path) => path.label === paramVal);
  if (path) {
    path = path[0];
  }

  // fetch profile data to get the paths inside
  const {
    isLoading: isLoadingProfile,
    error,
    data: profile = null,
  } = useQuery(
    "profile",
    async () => {
      const res = await getProfile({
        id: localStorage.getItem("userId"),
      });
      return res.data.data;
    },
    {
      enabled: enabledProfileFetch,
    }
  );

  const isLastQuestion =
    params.contentId === path?.contents[path?.contents?.length - 1].id;

  const handleSaveQuestion = () => {
    if (isLastQuestion || searchParams.get("from") === "redesigning_ui") {
      const profilePathExist = profile.paths.find(
        (p: any) => p.id === paths[path.id - 1].id
      );
      if (!profilePathExist) {
        addProfilePath({
          data: paths[path.id - 1],
        });
      }
      return;
    }
  };

  return {
    isLastQuestion,
    handleSaveQuestion,
  };
}
