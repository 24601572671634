import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface Props {
  isSubmitted: boolean;
  selectedItem: string;
  submitAnswer: (selectedAnswer: string) => any;
  submitLoading: boolean;
  testQuestions: any;
  correctAnswer: string;
  onSkipQuestion: () => void;
  onNextRoute: () => void;
}

const BottomBar: FC<Props> = ({
  isSubmitted,
  testQuestions,
  selectedItem,
  submitLoading,
  onSkipQuestion,
  correctAnswer,
  onNextRoute,
  submitAnswer,
}) => {
  const navigate = useNavigate();
  const params: any = useParams();

  const setBgColor = () => {
    if (!isSubmitted) return "rgba(51, 51, 51, 0.9)";
    if (correctAnswer === selectedItem) {
      return "rgba(30, 217, 130, 0.96)";
    } else {
      return "rgba(245, 103, 93, 0.96)";
    }
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        zIndex: 10,
        p: {
          xs: 0,
          sm: 0,
          md: 4,
          lg: 4,
        },
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: {
            xs: "100%",
            sm: "100%",
            md: "1000px",
            lg: "1000px",
          },
          mx: "auto",
          backgroundColor: setBgColor,
          p: 2,
          borderRadius: {
            xs: "0",
            sm: "0",
            md: "20px",
            lg: "20px",
          },
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
            lg: "row",
          },
          justifyContent: "space-between",
        }}
      >
        {!isSubmitted ? (
          <Button
            onClick={onSkipQuestion}
            variant="outlined"
            sx={{
              px: 2,
              py: 1,
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "flex",
              },
              border: "1px solid #a7a7a7",
              "&:hover": {
                border: "1px solid #a7a7a7",
                backgroundColor: "grey.600",
              },
            }}
          >
            <Typography sx={{ color: "#a7a7a7" }}>Skip</Typography>
          </Button>
        ) : isSubmitted && correctAnswer === selectedItem ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                backgroundColor: "common.white",
                borderRadius: "100%",
                width: "35px",
                height: "35px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CheckIcon
                sx={{
                  width: "30px",
                  stroke: "#027A48",
                  strokeWidth: 3,
                }}
              />
            </Box>
            <Typography
              sx={{
                ml: 1.5,
                color: "green.900",
                fontWeight: 700,
              }}
            >
              You got it!
            </Typography>
          </Box>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                backgroundColor: "common.white",
                borderRadius: "100%",
                width: "35px",
                height: "35px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CloseIcon
                sx={{
                  width: "30px",
                  stroke: "#bf5659",
                  strokeWidth: 3,
                }}
              />
            </Box>
            <Typography
              sx={{
                ml: 1.5,
                color: "red.900",
                fontWeight: 700,
              }}
            >
              Incorrect
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "300px",
              lg: "300px",
            },
            mb: {
              xs: 2,
              sm: 2,
              md: 0,
              lg: 0,
            },
          }}
        >
          <Box>
            <Typography
              variant="body2"
              sx={{
                color:
                  isSubmitted && correctAnswer === selectedItem
                    ? "green.900"
                    : isSubmitted && correctAnswer !== selectedItem
                    ? "red.900"
                    : "grey.50",
              }}
            >
              Question {params.contentId} of {testQuestions?.length}
            </Typography>
            <LinearProgress
              variant="determinate"
              // @ts-ignore
              value={Math.round(
                (100 / testQuestions?.length) * parseInt(params.id)
              )}
              sx={{
                mt: 1,
                backgroundColor:
                  isSubmitted && correctAnswer === selectedItem
                    ? "green.500"
                    : isSubmitted && correctAnswer !== selectedItem
                    ? "red.500"
                    : "grey.600",
                height: "10px",
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "300px",
                  lg: "300px",
                },
                borderRadius: "8px",
                ".MuiLinearProgress-bar": {
                  backgroundColor:
                    isSubmitted && correctAnswer === selectedItem
                      ? "green.50"
                      : isSubmitted && correctAnswer !== selectedItem
                      ? "red.100"
                      : "grey.50",
                },
              }}
            />
          </Box>
        </Box>
        {isSubmitted ? (
          <Button
            variant="contained"
            sx={{
              py: 1,
              width: {
                xs: "100%",
                sm: "100%",
                md: testQuestions?.length == params.id ? "160px" : "100px",
                lg: testQuestions?.length == params.id ? "160px" : "100px",
              },
              backgroundColor:
                correctAnswer === selectedItem ? "green.800" : "red.800",
              "&:hover": {
                backgroundColor:
                  correctAnswer === selectedItem ? "green.800" : "red.800",
              },
            }}
            // @ts-ignore
            onClick={() => onNextRoute()}
            endIcon={<ArrowForwardIcon />}
          >
            <Typography>
              {testQuestions?.length == params.id ? `View Result` : `Next`}
            </Typography>
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={() => submitAnswer(selectedItem)}
            sx={{
              py: 1,
              color: "#FFF",
              width: {
                xs: "100%",
                sm: "100%",
                md: "100px",
                lg: "100px",
              },
              backgroundColor: "#2e69ff",
              "&:hover": {
                backgroundColor: "#2e69ff",
              },
              "&.Mui-disabled": {
                backgroundColor: "#2e69ff",
                opacity: 0.4,
              },
            }}
            disabled={!selectedItem}
          >
            {submitLoading ? (
              <CircularProgress
                size="1rem"
                sx={{ color: "common.white" }}
                thickness={5}
              />
            ) : (
              <Typography sx={{ color: "common.white" }}>Submit</Typography>
            )}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default BottomBar;
