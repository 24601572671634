import React, { FC, useState } from "react";
import { TextField, Box, Dialog, DialogContent, Divider, DialogContentText, DialogTitle, DialogActions, Button, Typography, IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import useUser from 'contexts/User';
import useTest from "contexts/Test";
import { ReactComponent as ButtonPanaIcon } from "assets/images/button-pana.svg"
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as DogIcon } from "assets/images/dog-paw.svg"
import { LoadingButton } from '@mui/lab';
import CheckIcon from '@mui/icons-material/Check';
interface Props {
  onClose: () => void;
};

const FeedbackForm = ({
  onClose
}: Props) => {
  const navigate = useNavigate();
  const { userDetails } = useUser();
  const { sendFeedback } = useTest();
  const [message, setMessage] = useState<string>("");
  const [sendLoading, setSendLoading] = useState<boolean>(false);
  const [isSent, setIsSent] = useState<boolean>(false);
  const [firstQuestionOptions, setFirstQuestionOptions] = useState([
    {
      id: "1",
      label: "No, I haven't learned anything",
      isSelected: false
    },
    {
      id: "2",
      label: "Yes, a bit",
      isSelected: false
    },
    {
      id: "3",
      label: "Yes, a lot",
      isSelected: false
    }
  ]);
  const [secondQuestionOptions, setSecondQuestionOptions] = useState([
    {
      id: "1",
      label: "No, I don't",
      isSelected: false
    },
    {
      id: "2",
      label: "I'm not sure",
      isSelected: false
    },
    {
      id: "3",
      label: "Yes, absolutely",
      isSelected: false
    }
  ]);
  const [thirdQuestionOptions, setThirdQuestionOptions] = useState([
    {
      id: "1",
      label: "No, I'm not willing",
      isSelected: false
    },
    {
      id: "2",
      label: "I'm not sure",
      isSelected: false
    },
    {
      id: "3",
      label: "Yes, I'm willing",
      isSelected: false
    }
  ]);
  const [selectedFirstQuestion, setSelectedFirstQuestion] = useState("");
  const [selectedSecondQuestion, setSelectedSecondQuestion] = useState("");
  const [selectedThirdQuestion, setSelectedThirdQuestion] = useState("");

  const timeout = (ms: any) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 100,
        m: {
          xs: 2,
          sm: 2,
          md: 4,
          lg: 4
        },
        left: 0,
        bottom: 0,
      }}
    >
      <DogIcon
        style={{
          width: '100px',
          marginBottom: -15,
          zIndex: 10,
          position: 'relative'
        }}
      />
      <Box 
        sx={{ 
          width: {
            xs: '100%',
            sm: '100%',
            md: '450px',
            lg: '450px'
          }, 
          backgroundColor: '#FFF',
          zIndex: 11,
          position: 'relative',
          p: 2,
          boxShadow: '0 8px 30px rgba(0,0,0,.12)',
          borderRadius: 4
        }}
      >
        {isSent ? (
          <Box sx={{ display: "flex", justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex' }}>
              <CheckIcon sx={{ color: 'green.600' }} />
              <Typography variant="body2" sx={{ color: 'green.600', ml: 1 }}>
                Thank you for your feedback
              </Typography>
            </Box>
            <IconButton
              onClick={() => onClose()}
              sx={{ p: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        ) : (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h5" fontWeight={500}>
                {/* @ts-ignore */}
                Hey, there! Give us some feedback
              </Typography>
              <IconButton
                onClick={() => onClose()}
                sx={{ p: 0 }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" fontWeight={400}>
                1. Did you learn anything after taking the test?
              </Typography>
              <Box sx={{ display: 'flex' }}>
                {firstQuestionOptions.map((option: any, key: number) => (
                  <Box 
                    onClick={() => {
                      setSelectedFirstQuestion(option.label);
                    }}
                    sx={{ 
                      borderRadius: 50,
                      p: 1,
                      mt: 1,
                      borderStyle: 'solid',
                      borderWidth: '2px',
                      borderColor:
                        (selectedFirstQuestion === option.label) ? 'info.main' : 'grey.200',
                      backgroundColor: (selectedFirstQuestion === option.label) ? 'blue.100' : 'common.white',
                      color: (selectedFirstQuestion === option.label) ? 'info.main' : 'text.primary',
                      cursor: 'pointer',
                      mr: 1
                    }}
                  >
                    <Typography 
                      variant="body2" 
                      color="text.secondary"
                      sx={{
                        fontSize: {
                          xs: 9.3,
                          sm: 9.3,
                          md: 14,
                          lg: 14
                        }
                      }}
                    >
                      {option.label}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>

            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" fontWeight={400}>
                2. Do you want to continue learning with this type of test?
              </Typography>
              <Box sx={{ display: 'flex' }}>
                {secondQuestionOptions.map((option: any, key: number) => (
                  <Box 
                    onClick={() => {
                      setSelectedSecondQuestion(option.label);
                    }}
                    sx={{
                      borderRadius: 50,
                      p: 1,
                      mt: 1,
                      borderStyle: 'solid',
                      borderWidth: '2px',
                      borderColor:
                        (selectedSecondQuestion === option.label) ? 'info.main' : 'grey.200',
                      backgroundColor: (selectedSecondQuestion === option.label) ? 'blue.100' : 'common.white',
                      color: (selectedSecondQuestion === option.label) ? 'info.main' : 'text.primary',
                      cursor: 'pointer',
                      mr: 1
                    }}
                  >
                    <Typography 
                      variant="body2" 
                      color="text.secondary"
                      sx={{
                        fontSize: {
                          xs: 9.3,
                          sm: 9.3,
                          md: 14,
                          lg: 14
                        }
                      }}
                    >
                      {option.label}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>

            {/* <Box sx={{ mt: 2 }}>
              <Typography variant="body2" fontWeight={400}>
                3. Are you willing to pay to learn more with us?
              </Typography>
              <Box sx={{ display: 'flex' }}>
                {thirdQuestionOptions.map((option: any, key: number) => (
                  <Box
                    onClick={() => {
                      setSelectedThirdQuestion(option.label);
                    }}
                    sx={{
                      borderRadius: 50,
                      p: 1,
                      mt: 1,
                      borderStyle: 'solid',
                      borderWidth: '2px',
                      borderColor:
                        (selectedThirdQuestion === option.label) ? 'info.main' : 'grey.200',
                      backgroundColor: (selectedThirdQuestion === option.label) ? 'blue.100' : 'common.white',
                      color: (selectedThirdQuestion === option.label) ? 'info.main' : 'text.primary',
                      cursor: 'pointer',
                      mr: 1
                    }}
                  >
                    <Typography 
                      variant="body2" 
                      color="text.secondary"
                      sx={{
                        fontSize: {
                          xs: 9.3,
                          sm: 9.3,
                          md: 14,
                          lg: 14
                        }
                      }}
                    >
                      {option.label}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box> */}
            <TextField 
              id="outlined-basic" 
              placeholder="Comments / Suggestions"
              onChange={(e) => setMessage(e.target.value)}
              multiline
              maxRows={4}
              sx={{
                mt: 2,
                backgroundColor: '#FFF',
                width: '100%',
                'textarea': {
                  minHeight: '70px',
                  color: 'text.secondary',
                  fontSize: 14,
                  p: 1,
                  fontWeight: 400
                }
              }}
            />
            <Box sx={{ display: 'flex', mt: 1, justifyContent: 'space-between' }}>
              <Box></Box>
              {!isSent && (
                <LoadingButton
                  variant="contained"
                  onClick={async() => {
                    try {
                      setSendLoading(true);
                      sendFeedback({
                        message,
                        selectedFirstQuestion,
                        selectedSecondQuestion,
                        selectedThirdQuestion
                      });
                      await timeout(500);
                    } catch(err) {
                      console.log(err);
                    } finally {
                      setSendLoading(false);
                      setIsSent(true);
                    }
                  }}
                  loading={sendLoading}
                  sx={{
                    backgroundColor: '#2e69ff',
                    '&:hover': {
                      backgroundColor: '#2e69ff',
                    },
                  }}
                >
                  Send
                </LoadingButton>
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default FeedbackForm;