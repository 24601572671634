export const initialState = {
    showSuccessPayment: false,
    title: "",
    message: "",
    type: "",
    action: "close"
  }
  
  const PaymentReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
      case "TOGGLE":
        return {
          ...state,
          showSuccessPayment: state.showSuccessPayment = payload
        }
      case "SUCCESS":
        return {
          ...state,
          show: state.showSuccessPayment = true,
          title: state.title = state.title,
          message: state.message = payload.message,
          type: state.type = "SUCCESS"
        }
      case "ERROR":
        return {
          ...state,
          show: state.showSuccessPayment = true,
          title: state.title = state.title,
          message: state.message = payload.message,
          type: state.type = "ERROR"
        }
      case "REMOVE":
        return {
          ...state,
          show: state.showSuccessPayment = false,
          title: state.title = "",
          message: state.message = "",
          type: state.type = ""
        }
      default:
        throw new Error('No case type found')
    }
  }
  
  export default PaymentReducer;