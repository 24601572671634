import { Box } from "@mui/material";
import { GoogleBtn } from "components/Buttons";
import useAlert from "contexts/Alert";
import useUser from "contexts/User";
import useSaveQuestion from "hooks/useSaveQuestion";
import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
/**
 * @Author Jacob
 * @Description This component is used for /request/mentorship and /login
 * @Method N/A
 * @Status Done
 */
interface Props {
  width?: number | string;
}

const LoginForm: FC<Props> = ({ width }) => {
  const { checkToken, loginGoogle } = useUser();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { handleSaveQuestion } = useSaveQuestion();
  const { dispatchAlert } = useAlert();
  const [loadingGoogle, setLoadingGoogle] = useState(false);
  const [loadingFacebook, setLoadingFacebook] = useState(false);
  const location = useLocation();
  let from = ((location.state as any)?.from?.pathname as string) || "/";

  const googleLogin = () => {
    // let googleLoginApi = submitGoogleLogin;
    let gapiInit: any = window.gapi;
    window.gapi.load("auth2", function () {
      var auth2 = gapiInit.auth2.init({
        client_id:
          "538942194991-rmd7eq6guulpv9bqas3mtmak1lfo6dq2.apps.googleusercontent.com",
        cookiepolicy: "single_host_origin",
      });
      // auth event handler
      auth2.attachClickHandler(
        document.getElementById("google-btn"),
        {},
        async function (googleUser: any) {
          const profile = googleUser.getBasicProfile();
          // The ID token passed to backend
          let id_token = googleUser.getAuthResponse().id_token;
          let email = profile.getEmail();
          setLoadingGoogle(true);
          try {
            //Request Payload
            const requestData = {
              firstName: profile.Af, // find a way to change this key to dynamic
              lastName: profile.IX, // find a way to change this key to dynamic
              email: email,
              token: id_token,
              password: "",
              thumbnailImage: profile.tO, // find a way to change this key to dynamic
              originalImage: profile.tO, // find a way to change this key to dynamic
              username: profile.tf,
              scope: googleUser.getAuthResponse().scope,
              role: "user",
              type: "google",
            };
            // API Request
            await submitGoogleLogin(requestData, "googleLogin");
          } catch (err) {
            console.log(err);
          } finally {
            setLoadingGoogle(false);
          }
        }
      );
    });
  };

  const submitGoogleLogin = async (form: any, type: string) => {
    try {
      // API Call
      const res = await loginGoogle(form);
      // API Response
      const data = res.data.data;
      console.log("data.accessToken", data.accessToken);
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("userId", data.userId);
      localStorage.setItem("profileImage", data.profileImage);
      localStorage.setItem("firstName", data.firstName);
      localStorage.setItem("lastName", data.lastName);
      localStorage.setItem("membershipDetails", data?.membershipDetails);
      if (searchParams.get("from") === "redesigning_ui") {
        handleSaveQuestion();
        navigate("/paths");
        // save question results in db
      } else {
        navigate("/paths");
      }
    } catch (err) {
      console.log("submitGoogleLogin", err);
    } finally {
      setLoadingGoogle(false);
    }
  };

  useEffect(() => {
    googleLogin();
  }, []);

  return (
    <Box
      sx={{
        width: {
          xs: "100%",
          sm: "100%",
          md: width,
          lg: width,
        },
        backgroundColor: "#FFF",
        borderRadius: "4px",
      }}
    >
      <Box>
        <GoogleBtn loading={loadingGoogle} />
        {/* GITHUB */}
        {/* <MuiLink
          href={getGitHubUrl(from)}
          sx={{
            borderRadius: 1,
            mt: 2,
            columnGap: '1rem',
            textDecoration: 'none',
            color: '#FFF',
            cursor: 'pointer',
            width: '100%',
            height: '50px',
            backgroundColor: '#1b1f23',
            fontWeight: 500,
            border: '1px solid #d9d9d9',
            boxShadow: '0 1px 3px #3330000d, 0 1px 1px #33300006',
            '&:hover': {
              backgroundColor: '#000',
              boxShadow: '0 1px 13px 0 rgb(0 0 0 / 15%)',
            },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <GitHubIcon fontSize="medium" />
          <Typography 
            variant="subtitle1" 
            sx={{ 
              margin: '0 0.9em 0em 0',
              float: 'right'
            }}
          >
            Continue with Github
          </Typography>
        </MuiLink> */}
      </Box>
    </Box>
  );
};

LoginForm.defaultProps = {
  width: 450,
};

export default LoginForm;
