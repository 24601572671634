import { createContext, useReducer, useContext, useMemo } from "react"
import testReducer, { initialState } from "./reducer"
import { patchRequest, postRequest } from "utils/api"

const TestContext = createContext();

export const TestProvider = ({ children }) => {
  const [state, dispatch] = useReducer(testReducer, initialState);

  const setCorrectAnswers = async (data) => {
    dispatch({
      type: "SET_CORRECT_ANSWERS",
      payload: data
    })
  };

  const sendFeedback = async (data) => {
    const payload = {
      url: '/v1/audit-log',
      data: {
        field: "feedback_msg",
        message: data
      }
    };
    const res = await patchRequest(payload);
    return res;
  };

  const value = useMemo(() => {
    return {
      correctAnswers: state.correctAnswers,
      setCorrectAnswers,
      sendFeedback
    }
  }, [
    state.correctAnswers,
    setCorrectAnswers,
    sendFeedback
  ]);

  return (
    <TestContext.Provider value={value}>
      {children}
    </TestContext.Provider>
  )
}

const useTest = () => {
  const context = useContext(TestContext)

  if (context === undefined) {
    throw new Error("useTest must be used within shopcontext")
  }
  return context;
}

export default useTest;