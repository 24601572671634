import { Button, Chip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import AuthNavigation from "components/Navigations/AuthNavigation";
import UnauthNavigation from "components/Navigations/UnauthNavigation";
import { Paths } from "components/Paths";
import useUser from "contexts/User";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BookModal from "./components/BookModal";
import FeedbackForm from "./components/FeedbackForm";

const Home: FC = () => {
  const { updateAuditLog } = useUser();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [showBookModal, setShowBookModal] = useState(false);

  // used for audit
  useEffect(() => {
    if (searchParams.get("show") === "feedback") {
      updateAuditLog({
        field: "finished_test_cnt",
      });
    } else {
      updateAuditLog({
        field: "visited_home_page",
      });
    }
  }, []);

  // used for feedback
  useEffect(() => {
    if (searchParams.get("show") === "feedback") {
      setShowFeedbackForm(true);
    }
  }, []);

  return (
    <>
      {localStorage.getItem("accessToken") ? (
        <AuthNavigation />
      ) : (
        <UnauthNavigation />
      )}
      <Box
        sx={{
          pt: {
            sm: 10,
            xs: 10,
            md: 15,
            lg: 15,
          },
          mb: 15,
          minHeight: "100vh",
        }}
      >
        {showFeedbackForm && (
          <FeedbackForm onClose={() => setShowFeedbackForm(false)} />
        )}
        <Box
          sx={{
            px: {
              xs: 2,
              sm: 2,
              md: 0,
              lg: 0,
            },
          }}
        >
          <Box
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "1300px",
                lg: "1300px",
              },
              mx: "auto",
              py: 3,
              display: "flex",
              justifyContent: "space-between",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
              },
            }}
          >
            <Box sx={{ width: "50%" }}></Box>
            <Box
              sx={{
                width: "90%",
                mx: "auto",
                order: 2,
              }}
            >
              <Paths />
            </Box>
            <Box
              sx={{
                width: "100%",
                order: {
                  xs: 1,
                  sm: 1,
                  md: 3,
                  lg: 3,
                },
                pl: {
                  sm: 0,
                  xs: 0,
                  md: 20,
                  lg: 20,
                },
                mb: {
                  sm: 4,
                  xs: 4,
                  md: 0,
                  lg: 0,
                },
              }}
            >
              <Box
                sx={{
                  position: "sticky",
                  top: "120px",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#FAFAFA",
                    p: 3,
                    borderRadius: 3,
                    border: "1px solid #eaeaea",
                    minWidth: "100%",
                    color: "#142038",
                    alignItems: "center",
                    mt: 3,
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "flex",
                      lg: "flex",
                    },
                  }}
                >
                  <img
                    src={require("../../assets/images/ux-book.png")}
                    style={{
                      width: "170px",
                      boxShadow: "0 2px 4px #c2c0c0",
                    }}
                  />
                  <Box sx={{ ml: 2 }}>
                    <Chip
                      label="Ebook"
                      sx={{
                        backgroundColor: "rgba(255, 0, 128, 0.1)",
                        height: "26px",
                        color: "#FF0080",
                        mb: 1,
                        span: {
                          px: 1.3,
                        },
                      }}
                    />
                    <Typography variant="body1" sx={{ mt: 1, fontWeight: 300 }}>
                      A compendium of solutions for real-life UX problems
                    </Typography>
                    {/* <Typography variant="body2" sx={{ mt: 1, fontWeight: 300 }}>
                      Get full copy by becoming a pro member
                    </Typography> */}
                    <Button
                      variant="outlined"
                      sx={{ mt: 1 }}
                      onClick={() => setShowBookModal(true)}
                    >
                      View sneak peek
                    </Button>
                  </Box>
                  {showBookModal && (
                    <BookModal onClose={() => setShowBookModal(false)} />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Home;
