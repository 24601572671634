import { getRequest, putRequest } from "utils/api";

export const getPaths = async ({ id }) => {
  console.log("id", id)
  const payload = {
    url: `/paths/v1/list/${id}`
  };
  const res = await getRequest(payload);
  return res.data;
};

export const updatePath = async ({ pathId, contents, read }) => {
  const payload = {
    url: `/paths/v1/update/${localStorage.getItem("userId")}`,
    data: {
      pathId,
      contents,
      read
    }
  };
  const res = await putRequest(payload);
  return res.data;
};