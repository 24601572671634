import React, { FC } from "react";
import { Box } from "@mui/material";
import PricingSection from "components/PricingSection";

const Pricing: FC = () => {
  return (
    <Box
      sx={{
        py: 15
      }}
    >
      <PricingSection />
    </Box>
  );
}
export default Pricing;