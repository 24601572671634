import React, { FC, useState, useEffect, useRef } from "react";
import { Grid, Skeleton, Button, Box, Divider, Typography, ListItem, ListItemAvatar, Avatar } from "@mui/material";
import { useParams } from "react-router-dom";
import useProfile from "contexts/Profile";
import EditAboutModal from "./ProfileEditAboutModal/ProfileEditAboutModal";
import useUser from "contexts/User";
import { styledFlexWrapper, styledPageWrapper } from "styles";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import PreviewIcon from '@mui/icons-material/Preview';

interface ProfileDetails {
  userId: string;
  firstName: string;
  lastName: string;
  about: string;
  hourlyRate: string;
  availabilityStatus: string;
  profileImage: ProfileImage;
  experience: Array<any>;
  expertise: Array<any>;
  projects: Array<any>;
  awards: Array<any>;
}

interface ProfileImage {
  originalImage: string;
  thumbnailImage: string;
}

const Profile: FC = () => {
  const params = useParams()
  const file = useRef<any>()
  const { getProfile } = useProfile()
  
  // profile and user details shape
  const { userDetails } = useUser()
  const [profileDetails, setProfileDetails] = useState<ProfileDetails>({
    userId: "",
    firstName: "",
    lastName: "",
    about: "",
    hourlyRate: "",
    availabilityStatus: "",
    profileImage: {
      originalImage: "",
      thumbnailImage: ""
    },
    experience: [],
    expertise: [],
    projects: [],
    awards: []
  })

  // 
  const [loading, setLoading] = useState(false)
  const [rating, setRating] = useState(null)
  const [showAboutModal, setShowAboutModal] = useState(false)

  useEffect(() => {
    getProfileDetails()
  }, [params.id])

  const handleFileClick = () => {
    file.current.click()
  }

  // Message btn function
  const handleMessageBtn = () => {
    // setNewMessage({
    //   userId: profileDetails.userId,
    //   lastMessage: {
    //     message: '',
    //     seen: true
    //   },
    //   minimize: false,
    //   profileId: params.id,
    //   conversationId: Math.random().toString(10).substring(2, 15) + Math.random().toString(10).substring(2, 15),
    //   name: `${profileDetails.firstName} ${profileDetails.lastName}`,
    //   thumbnailImage: profileDetails.profileImage.thumbnailImage,
    //   exist: false
    // })
  }

  // Upload profile image
  const uploadImage = async (event: any) => {
    let files = event.target.files || event.dataTransfer.files;
    try {
      // request
      let data = new FormData()
      data.append('file', files[0])

      // API call
      // const response = await uploadFile(data)
      // pushSelectedFiles(response.data.data)
    } catch (err) {
      console.log(err)
    } finally {
    }
  }

  // fetch Profile details using api
  const getProfileDetails = async () => {
    setLoading(true)
    try {
      const response = await getProfile({ id: params.id })
      setProfileDetails(response.data.data)
    } catch (err) {
      console.log("getProfileDetails", err)
    } finally {
      setLoading(false)
    }
  };

  const ProfileDetails = () => {
    return (
      <Box 
        sx={{
          width: '470px',
          position: 'sticky',
          top: '5rem'
        }}
      >
        <Typography variant="body2">
          Mentor
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Typography variant="h5">
            P400
          </Typography>
          <Typography variant="body2">
            For every 30 mins
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Box sx={{ py: 1 }}>
            <Button
              sx={{ p: 0 }}
              startIcon={<LinkedInIcon />}
            >
              LinkedIn
            </Button>
          </Box>
          <Box sx={{ py: 1 }}>
            <Button
              sx={{ p: 0 }}
              startIcon={<GitHubIcon />}
            >
              Github
            </Button>
          </Box>
          <Box sx={{ py: 1 }}>
            <Button
              sx={{ p: 0 }}
              startIcon={<PreviewIcon />}
            >
              Portfolio
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  const AboutSection = () => {
    return (
      <Box
        sx={{
          borderStyle: 'solid',
          borderWidth: '0.5px',
          borderColor: 'grey.200',
          borderRadius: '6px',
          p: 3,
          mb: 4
        }}
      >
        <Typography variant="h4">
          About Me
        </Typography>
        <Typography 
          variant="body2" 
          sx={{ 
            color: 'text.secondary',
            mt: 2
          }}
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae inventore commodi similique neque, suscipit quos officiis ipsam! Laudantium minus ducimus quod repellendus et consequatur, omnis architecto exercitationem voluptatum, vitae incidunt.
        </Typography>
      </Box>
    );
  };

  const ProfileBackground = () => {
    return (
      <Box
        sx={{
          borderStyle: 'solid',
          borderWidth: '0.5px',
          borderColor: 'grey.200',
          borderRadius: '6px',
          p: 3
        }}
      >
        <Typography variant="h4">
          Expertise
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <Typography variant="h6">ReactJS</Typography>
            <Typography variant="body2" sx={{ ml: 1, color: 'grey.600' }}>
              1 year/s experience
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ color: 'text.secondary', mt: 2 }}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae inventore commodi similique neque, suscipit quos officiis ipsam! Laudantium minus ducimus quod repellendus et consequatur, omnis architecto exercitationem voluptatum, vitae incidunt.
          </Typography>
          <Divider style={{ margin: '1rem 0' }} />
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <Typography variant="h6">ReactJS</Typography>
            <Typography variant="body2" sx={{ ml: 1, color: 'grey.600' }}>
              1 year/s experience
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ color: 'text.secondary', mt: 2 }}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae inventore commodi similique neque, suscipit quos officiis ipsam! Laudantium minus ducimus quod repellendus et consequatur, omnis architecto exercitationem voluptatum, vitae incidunt.
          </Typography>
        </Box>
    </Box>
    );
  };

  return (
    <Box sx={styledPageWrapper}>
      <Box sx={styledFlexWrapper}>
        <Box
          style={{
            margin: '0',
            minHeight: '100vh'
          }}
        >
          {loading ? (
            <Grid container sx={{ backgroundColor: '#FFF', border: '1px solid #EAEAEA' }}>
              <Grid md={1} sm={1} sx={{ p: 2 }}>
                <Skeleton variant="circular" width={100} height={100} />
              </Grid>
              <Grid md={9} sm={9} sx={{ p: 5 }}>
                <Skeleton style={{ width: '300px' }} />
                <Skeleton style={{ width: '300px' }} />
              </Grid>
            </Grid>
          ) : (
            <>
              <Box 
                sx={{
                  py: 4,
                  ...styledFlexWrapper,
                  backgroundColor: '#FFF',
                  borderBottomStyle: 'solid',
                  borderBottomColor: 'grey.200',
                  borderBottomWidth: '0.5px',
                }}
              >
                <Box 
                  sx={{
                    width: '1100px', 
                    m: 'auto'
                  }}
                >
                  <ListItem sx={{ p: 0 }}>
                    <ListItemAvatar>
                      <Avatar
                        onClick={() => handleFileClick()}
                        src={profileDetails.profileImage.thumbnailImage}
                        sx={{ 
                          width: 80, 
                          height: 80 
                        }}
                      />
                      <input
                        type="file"
                        id="up"
                        ref={file}
                        style={{ display: 'none' }}
                        onChange={(e) => uploadImage(e)}
                      />
                    </ListItemAvatar>
                    <Typography variant="h3" sx={{ ml: 3 }}>
                      {profileDetails.firstName} {profileDetails.lastName}
                    </Typography>
                  </ListItem>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '1100px', 
                  m: 'auto',
                  py: 6
                }}
              >
                <ProfileDetails />
                <Box>
                  <AboutSection />
                  <ProfileBackground />
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default Profile;