import { createContext, useReducer, useContext, useMemo } from "react"
import profileReducer, { initialState } from "./reducer"
import { getRequest, patchRequest, postRequest } from "utils/api"

const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const [state, dispatch] = useReducer(profileReducer, initialState);

  const getProfile = async ({ id }) => {
    const payload = {
      url: `/profile/v1/get/${id}`
    }
    const res = await getRequest(payload)
    dispatch({
      type: "SET_PROFILE_DETAILS",
      payload: res.data.data
    })
    return res
  }

  const addProfilePath = async ({ data }) => {
    const payload = {
      url: `/profile/v1/path`,
      data
    }
    const res = await patchRequest(payload)
    dispatch({
      type: "SET_PROFILE_DETAILS",
      payload: res.data.data
    })
    return res
  }

  const value = useMemo(() => {
    return {
      profileDetails: state.profileDetails,
      addProfilePath,
      getProfile
    }
  }, [
    state.profileDetails,
    addProfilePath,
    getProfile
  ])

  return (
    <ProfileContext.Provider value={value}>
      {children}
    </ProfileContext.Provider>
  )
}

const useProfile = () => {
  const context = useContext(ProfileContext)
  if (context === undefined) {
    throw new Error("useProfile must be used within profileContext")
  }
  return context
}

export default useProfile