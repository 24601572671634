import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, IconButton, Typography } from "@mui/material";
import useUser from "contexts/User";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { usePath } from "./hooks/usePath";
import ReviewResult from "./ReviewResult";
interface Props {
  pathLabel: string;
  pathName: string;
  pathTotalResult: number;
}

const ResultNotif = ({ pathLabel, pathName, pathTotalResult }: Props) => {
  const { updateAuditLog } = useUser();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const { paths, path } = usePath({ pathLabel });

  // @ts-ignore
  const [showBreakdown, setShowBreakdown] = useState(false);

  // set total correct answers
  useEffect(() => {
    let count = 0;
    path?.contents.forEach((content: any) => {
      if (
        content?.question.correctAnswer === content?.question.selectedAnswer
      ) {
        count += 1;
      }
    });
    setCorrectAnswers(count);
  }, []);

  return (
    <Box>
      <Box
        sx={{
          p: 2,
          justifyContent: "space-between",
          backgroundColor: "common.white",
          zIndex: 10,
          minWidth: "300px",
          borderRadius: 4,
          alignItems: "center",
          boxShadow: "0 15px 40px rgba(0,0,0,0.1)",
        }}
      >
        <Box>
          <Typography variant="body2" color="text.secondary">
            {pathName}
          </Typography>
          <Typography>
            Test Result {pathTotalResult || correctAnswers}/
            {path?.contents?.length}
          </Typography>
          <Box
            sx={{
              display: "flex",
              mt: 1,
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              onClick={() => navigate(`/question/${pathLabel}/1`)}
              variant="contained"
              sx={{
                width: "100%",
                fontSize: 12,
                px: 0,
                backgroundColor: "green.600",

                "&:hover": {
                  backgroundColor: "green.600",
                },
              }}
            >
              Take again
            </Button>
          </Box>
          {path && showBreakdown && (
            <Dialog
              open={true}
              fullWidth
              maxWidth={"lg"}
              onClose={() => setShowBreakdown(false)}
            >
              <Box
                sx={{
                  px: 2,
                  py: 3,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography>Test result breakdown</Typography>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => setShowBreakdown(false)}
                >
                  <CloseIcon fontSize="medium" />
                </IconButton>
              </Box>
              <ReviewResult pathLabel={pathLabel} />
            </Dialog>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          width: 0,
          height: 0,
          position: "relative",
          borderLeft: "15px solid transparent",
          borderRight: "15px solid transparent",
          borderTop: "15px solid #FFF",
          mx: "auto",
        }}
      ></Box>
    </Box>
  );
};

export default ResultNotif;
