import React, { FC, useState } from "react";
import { 
  Dialog, 
  Box, 
  Typography,
  DialogContent, 
  Divider, 
  DialogContentText, 
  DialogTitle, 
  DialogActions, 
  IconButton,
  Button
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface Props {
  onClose?: () => void;
};

const ThankYouDialog = ({
  onClose
}: Props) => {
  const navigate = useNavigate();
  return (
    <Dialog 
      open={true}
      maxWidth={"xs"}
      onClose={onClose}
      PaperProps={{
        style: {
          maxWidth: "600px",
          paddingTop: '30px',
          paddingBottom: '30px'
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          px: 4
        }}
      >
        <Box></Box>
        <IconButton onClick={onClose}>
          <CloseIcon fontSize="large" />
        </IconButton>
      </Box>
      <DialogContent sx={{ textAlign: 'center', pt: 0 }}>
        <CheckCircleIcon 
          sx={{
            color: 'green.500',
            fontSize: 62
          }} 
        />
        <Typography variant="h1" color="green.600" fontWeight={500} mt={2}>
          Payment Successful!
        </Typography>
        <Typography mt={2} color="text.secondary" fontWeight={400} variant="body1">
          You are now a pro member. Learn by accessing the paths and finishing all the interactive exams.
        </Typography>
        <Button 
          variant="contained"
          disableElevation
          sx={{
            mt: 3,
            fontSize: 16,
            backgroundColor: 'info.main',
            '&:hover': {
              backgroundColor: 'info.main'
            }
          }}
          onClick={onClose}
        >
          Go to home
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ThankYouDialog;