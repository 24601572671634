import { createContext, useReducer, useContext, useMemo } from "react"
import userReducer, { initialState } from "./reducer"
import { getRequest, postRequest, patchRequest } from "utils/api"

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);

  const loginGoogle = async (request) => {
    const payload = {
      url: '/users/v1/google/login',
      data: request
    }
    const res = await postRequest(payload)
    return res
  }

  const logoutUser = async () => {
    const payload = {
      url: '/users/v1/logout'
    }
    const res = await postRequest(payload)
    if (res.data.data.success) {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("userId")
      dispatch({
        type: "EMPTY_USER_DETAILS",
        payload: {}
      })
    }
    return res
  };

  const updateAuditLog = async (request) => {
    const payload = {
      url: '/v1/audit-log',
      data: request
    };
    await patchRequest(payload);
  };

  const checkToken = async () => {
    const payload = {
      url: '/users/v1/check-token'
    }
    const res = await getRequest(payload)
    if (res.data.data.success) {
      dispatch({
        type: "SET_USER_DETAILS",
        payload: res.data.data.data
      })
    }
    return res
  }

  const setLoadingPage = (data) => {
    dispatch({
      type: "SET_LOADING_PAGE",
      payload: data
    })
  }

  const value = useMemo(() => {
    return {
      userDetails: state.userDetails,
      loadingPage: state.loadingPage,
      loginGoogle,
      logoutUser,
      updateAuditLog,
      setLoadingPage,
      checkToken
    }
  }, [
    state.userDetails,
    state.loadingPage,
    loginGoogle,
    updateAuditLog,
    setLoadingPage,
    logoutUser,
    checkToken
  ]);

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  )
}

const useUser = () => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error("useUser must be used within userContext")
  }
  return context
}

export default useUser