import React from "react";
import styles from "./ThemeWrapper.module.scss";
import useDisplay from "contexts/Display";

interface Props {
  children: any
  type?: string 
  margin?: number
  permanent?: boolean | string
  shadow?: boolean | string
}

const ThemeWrapper: React.FC<Props> = ({ children, type, margin, permanent, shadow }) => {
  const { displayMode } = useDisplay();

  // programatically set classNames via props
  // return a string containing the newly transformed set of props
  const setThemeClass = () => { 
    let styledClassName: string = `${displayMode.toLowerCase()}-${type}`;
    if(shadow) {
      styledClassName += "-shadow";
    }
    if(permanent) {
      styledClassName = `${type}-permanent`;
    }
    return styledClassName;
  }

  return (
    <div 
      className={styles[setThemeClass()]}
      style={{
        margin: `${margin}rem`
      }}
    >
      {children}
    </div>
  )
}

ThemeWrapper.defaultProps = {
  type: "primary",
  shadow: "",
  permanent: ""
}

export default ThemeWrapper;