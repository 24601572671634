import ArrowBack from "@mui/icons-material/ArrowBack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import BannerImage from "assets/images/banner-web.png";
import { ReactComponent as MainLogo } from "assets/mainLogo.svg";
import LoginForm from "components/Forms/LoginForm/LoginForm";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const Login: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [heading, setHeading] = useState(
    "Join us for free and accelerate your growth"
  );

  useEffect(() => {
    if (searchParams.get("from") === "redesigning_ui") {
      setHeading("Sign in for free to continue");
    }
  }, []);

  return (
    <>
      <Box
        className="SignupBackdrop"
        sx={{
          backgroundColor: "grey.50",
          borderRight: "1px solid #eaeaea",
          position: "absolute",
          width: "50vw",
          height: "100%",
          display: {
            xs: "none",
            lg: "block",
            md: "block",
          },
          zIndex: 0,
        }}
      ></Box>
      <Box
        className="SignupContent"
        sx={{
          maxWidth: {
            xs: "100%",
            md: "1200px",
            lg: "1200px",
          },
          width: "1200px",
          margin: "auto",
          height: "100vh",
          position: "relative",
          zIndex: 10,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            flex: 0.9,
            display: {
              xs: "none",
              lg: "block",
              md: "block",
            },
          }}
        >
          <Box sx={{ py: 15, px: 0 }}>
            <Box
              sx={{
                cursor: "pointer",
                display: "flex",
              }}
              onClick={() => navigate("/")}
            >
              <Box
                sx={{
                  width: "30px",
                  mt: -0.5,
                  height: "30px",
                  pl: 0.8,
                  backgroundImage:
                    "radial-gradient(145.05% 100% at 50% 0,#1d2b41 0,#0b1627 57.38%,#142133 88.16%)",
                  boxShadow: "0 5px 12px rgba(0,0,0,.12)",
                  pr: 0.7,
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <MainLogo />
              </Box>
              <Box sx={{ ml: 1.5 }}>
                <Typography variant="h5" fontSize={16}>
                  OneGuru
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                mt: 4,
                width: "400px",
                backgroundColor: "#FFF",
                borderRadius: "12px",
                boxShadow: "0 8px 30px rgba(0,0,0,.12)",
                overflow: "hidden",
              }}
            >
              {/* APPLE DISPLAY */}
              <Box
                sx={{
                  display: "flex",
                  py: 2,
                  px: 2,
                }}
              >
                <Box
                  sx={{
                    width: "12px",
                    height: "12px",
                    backgroundColor: "#ff5f56",
                    mx: 0.5,
                    borderRadius: "100%",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "12px",
                    height: "12px",
                    mx: 0.5,
                    backgroundColor: "#ffbd2e",
                    borderRadius: "100%",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "12px",
                    height: "12px",
                    mx: 0.5,
                    backgroundColor: "#27c93f",
                    borderRadius: "100%",
                  }}
                ></Box>
              </Box>
              <Divider />
              <img
                src={BannerImage}
                style={{
                  width: "100%",
                }}
              />
            </Box>
            <List sx={{ width: "600px", mt: 3 }}>
              <ListItem disablePadding>
                <ListItemIcon sx={{ minWidth: 35 }}>
                  <CheckCircleIcon sx={{ color: "green.500" }} />
                </ListItemIcon>
                <ListItemText>
                  Learn by taking interactive tests with detailed answers.
                </ListItemText>
              </ListItem>
              <ListItem disablePadding sx={{ pt: 1 }}>
                <ListItemIcon sx={{ minWidth: 35 }}>
                  <CheckCircleIcon sx={{ color: "green.500" }} />
                </ListItemIcon>
                <ListItemText>Improve your UI / UX skills with us</ListItemText>
              </ListItem>
            </List>
          </Box>
        </Box>
        <Box
          sx={{
            p: {
              xs: 3,
              lg: 0,
              md: 0,
            },
            ml: {
              md: 10,
            },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              mb: 5,
              fontSize: "38px",
            }}
          >
            {heading}
          </Typography>
          <LoginForm width={450} />
          <Divider sx={{ my: 5 }} />
          <Typography
            sx={{
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          >
            <ArrowBack sx={{ mb: -0.7, mr: 1 }} />
            <span style={{ color: "#303133" }}>Back to home</span>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Login;
