import { Box, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import testQuestions from "statics/testQuestions";
import ConfirmExitTestDialog from "./components/ConfirmExitTestDialog";
import Header from "./components/Header";
import WelcomeDialog from "./components/WelcomeDialog";

import { updatePath } from "contexts/Paths";
import useTest from "contexts/Test";
import { useMutation, useQuery } from "react-query";

// components
import useProfile from "contexts/Profile";
import useUser from "contexts/User";
import useSaveQuestion from "hooks/useSaveQuestion";
import { Path } from "utils/apiTypes";
import BottomBar from "./components/BottomBar";
import Choices from "./components/Choices";

const Question: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { updateAuditLog } = useUser();
  const { isLastQuestion, handleSaveQuestion } = useSaveQuestion();
  const params = useParams();
  const { addProfilePath, getProfile } = useProfile();
  const { correctAnswers, setCorrectAnswers } = useTest();
  const [testQuestion, setTestQuestion] = useState<any>();
  const [selectedItem, setSelectedItem] = useState<string>("");
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [showConfirmExitTest, setShowConfirmExitTest] =
    useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [showWelcomeDialog, setShowWelcomeDialog] = useState<boolean>(false);
  const [enabledProfileFetch, setEnabledProfileFetch] =
    useState<boolean>(false);

  // @ts-ignore
  const paths = JSON.parse(localStorage.getItem("paths"));
  let path = paths?.filter((path: Path) => path.label === params.label);
  if (path) {
    path = path[0];
  }

  // path mutation
  const { mutate: mutatePath, isLoading } = useMutation({
    mutationFn: ({ pathId, contents, read = false }: any) =>
      updatePath({ pathId, contents, read }),
    onSuccess: (data) => {
      console.log(data);
    },
    onError: (e) => {
      console.log(e, "Error in saving question");
    },
  });

  useEffect(() => {
    if (params.id === "1") {
      localStorage.removeItem("testQuestions");
      localStorage.setItem("testQuestions", JSON.stringify(testQuestions));
      setShowWelcomeDialog(true);
      updateAuditLog({
        field: "start_test_cnt",
      });
    }
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  // get paths
  useEffect(() => {
    path?.contents?.forEach((content: any) => {
      if (content?.id === params.contentId) {
        setTestQuestion(content?.question);
      }
    });
  }, []);

  const alertUser = (e: any) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const timeout = (ms: any) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  // fetch profile data to get the paths inside
  const {
    isLoading: isLoadingProfile,
    error,
    data: profile = null,
  } = useQuery(
    "profile",
    async () => {
      const res = await getProfile({
        id: localStorage.getItem("userId"),
      });
      return res.data.data;
    },
    {
      enabled: enabledProfileFetch,
    }
  );

  useEffect(() => {
    if (isLastQuestion) {
      setEnabledProfileFetch(true);
    } else {
      setEnabledProfileFetch(false);
    }
  }, []);

  const submitAnswer = async (selectedAnswer?: string) => {
    setSubmitLoading(true);
    try {
      if (isLastQuestion && !localStorage.getItem("accessToken")) {
        navigate("/login?from=redesigning_ui");
        return;
      }

      // scroll view to bottom
      await timeout(300);
      await window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

      if (selectedAnswer) {
        // set selected answer to content
        const content = path.contents.filter(
          (content: any) => content.id === params.contentId
        );
        content[0].question.selectedAnswer = selectedAnswer;

        // update paths' content
        // @ts-ignore
        paths[path.id - 1].contents[params?.contentId - 1] = content[0];

        // restore paths
        localStorage.setItem("paths", JSON.stringify(paths));
      }

      // save question results in db
      handleSaveQuestion();
    } catch (err) {
      console.log(err);
    } finally {
      setSubmitLoading(false);
      setIsSubmitted(true);
    }
  };

  const nextRoute = () => {
    if (params.contentId === path.contents[path.contents?.length - 1].id) {
      navigate("/paths?show=feedback");
    } else {
      // @ts-ignore
      navigate(`/question/${params.label}/${parseInt(params.contentId) + 1}`);
    }
  };

  const Explanation = () => {
    return (
      <Box
        sx={{
          my: 3,
          py: 2,
          px: 3,
          maxHeight: "300px",
          overflow: "auto",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "grey.200",
          borderRadius: "12px",
          mx: "auto",
          maxWidth: {
            xs: "100%",
            sm: "100%",
            md: "1000px",
            lg: "1000px",
          },
        }}
      >
        <Typography variant="body2" sx={{ fontSize: 16, color: "green.600" }}>
          Bonus!
        </Typography>

        <Typography variant="body2" sx={{ fontSize: 16, mt: 1 }}>
          Explanation:
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            fontWeight: 400,
            fontSize: 16,
            mt: 1,
            a: {
              color: "info.main",
              cursor: "pointer",
            },
          }}
          dangerouslySetInnerHTML={{ __html: testQuestion.answer }}
        ></Typography>
      </Box>
    );
  };

  return (
    <Box sx={{ minHeight: "120vh", pb: "200px" }}>
      <Box>
        <Header onClose={() => setShowConfirmExitTest(true)} />
        <Box
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "1000px",
              lg: "1000px",
            },
            p: {
              xs: 3,
              sm: 3,
              md: 0,
            },
            mx: "auto",
          }}
        >
          <Typography variant="h3" sx={{ mt: 1 }}>
            {testQuestion?.label}
          </Typography>
        </Box>
        {testQuestion?.answer && isSubmitted && <Explanation />}
        <Box
          sx={{
            mt: isSubmitted && !testQuestion?.answer ? 25 : 0,
          }}
        >
          <Choices
            testQuestion={testQuestion}
            selectedItem={selectedItem}
            isSubmitted={isSubmitted}
            onSetSelectedItem={setSelectedItem}
          />
        </Box>
      </Box>

      <BottomBar
        isSubmitted={isSubmitted}
        submitAnswer={submitAnswer}
        selectedItem={selectedItem}
        testQuestions={path?.contents}
        submitLoading={submitLoading}
        correctAnswer={testQuestion?.correctAnswer}
        onSkipQuestion={() => {
          setSelectedItem(testQuestion?.correctAnswer);
          submitAnswer();
        }}
        onNextRoute={() => nextRoute()}
      />
      {showConfirmExitTest && (
        <ConfirmExitTestDialog onClose={() => setShowConfirmExitTest(false)} />
      )}
      {showWelcomeDialog && (
        <WelcomeDialog onClose={() => setShowWelcomeDialog(false)} />
      )}
    </Box>
  );
};

export default Question;
