
import React, { FC, useEffect, useRef, useState } from "react";
import {
  Box,
  Chip,
  Skeleton,
  Typography
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
interface Props {
  testQuestion?: any;
  selectedItem?: string;
  isSubmitted?: boolean;
  onSetSelectedItem?: any;
};

const Choices: FC<Props> = ({
  testQuestion,
  selectedItem,
  isSubmitted,
  onSetSelectedItem
}) => {
  const imageEl = useRef<HTMLImageElement>();
  const [lazyLoading, setLazyLoading] = useState<Boolean>(true);
  // @ts-ignore
  let storedTestQuestions = JSON.parse(localStorage.getItem("testQuestions"));

  const handleImageLoad = () => {
    setLazyLoading(false);
  };

  const storeInMemory = () => {
    // @ts-ignore
    if(isSubmitted) {
      storedTestQuestions?.forEach((storedQuestion: any) => {
        if(storedQuestion.id === testQuestion.id) {
          storedQuestion.selectedAnswer = selectedItem
        }
      });
      localStorage.setItem("testQuestions", JSON.stringify(storedTestQuestions));
    }
  };

  useEffect(() => {
    storeInMemory();
  }, [isSubmitted, storedTestQuestions]);

  const Explanation = ({ choice }: any) => {
    return (
      <Box
        sx={{
          top: -165,
          mx: {
            md: 'auto',
            lg: 'auto'
          },
          width: {
            xs: '90%',
            sm: '90%',
            md: '100%',
            lg: '100%'
          },
          position: 'absolute',
        }}
      >

        {/* Explanation container */}
        <Box
          sx={{
            backgroundColor: '#FFF',
            borderRadius: 4,
            mx: 'auto',
            width: {
              xs: '100%',
              sm: '100%',
              md: '450px',
              lg: '450px'
            },
            height: '160px',
            overflowY: 'auto',
            boxShadow: '0 15px 40px rgba(0,0,0,0.12)',
            borderBottom: '5px solid #FFF'
          }}
        >
          {/* wrong or right icon */}
          <Box 
            sx={{ 
              mt: -2,
              ml: -1,
              position: 'absolute',
              backgroundColor: testQuestion.correctAnswer === choice.item ? 'green.300' : 'red.300',
              borderRadius: '100%',
              width: '30px',
              height: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {testQuestion.correctAnswer === choice.item ? (
              <CheckIcon 
                sx={{ 
                  color: 'green.700',
                  fontWeight: 800
                }} 
              />
            ) : (
              <CloseIcon 
                sx={{
                  color: 'red.700',
                  fontWeight: 800
                }}
              />
            )}
          </Box>

          {/* explanation */}
          <Box sx={{ py: 2, px: 2.5 }}>
            <Typography
              variant="body2" 
              sx={{
                fontWeight: 400,
                fontSize: 14.8,
                color: 'text.secondary'
              }}
              dangerouslySetInnerHTML={{ __html: choice.explanation }}
            >
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: 0,
            height: 0,
            mx: 'auto', 
            zIndex: 8,
            position: 'relative',
            borderLeft: '15px solid transparent',
            borderRight: '15px solid transparent',
            borderTop: '15px solid #FFFF'
          }}
        ></Box>
      </Box>
    );
  };

  return (
    <Box 
      sx={{
        display: testQuestion?.rows == 1 ? 'flex' : 'block',
        mt: 5,
        alignItems: 'center',
        justifyContent: 'center',
        mx: 'auto',
        flexDirection: {
          xs: 'column',
          sm: 'column',
          md: 'row',
          lg: 'row'
        },
        width: {
          xs: '100%',
          sm: '100%',
          md: '1000px',
          lg: '1000px'
        },
        maxWidth: {
          xs: '100%',
          sm: '100%',
          md: '1000px',
          lg: '1000px'
        }
      }}
    >
      {/* Choice selection container */}
      {testQuestion?.choices.map((choice: any, key: number) => (
        <>
          <Box
            onClick={() => {
              if(!isSubmitted) {
                onSetSelectedItem(choice.item)
              }
            }}
            sx={{ 
              display: 'flex',
              justifyContent: 'space-between',
              mb: {
                xs: isSubmitted ? 25: 5,
                sm: isSubmitted ? 25: 5,
                md: isSubmitted ? 23 : 7,
                lg: isSubmitted ? 23 : 7
              },
              position: 'relative',
              '&:nth-child(1)': {
                mr: {
                  md: testQuestion?.rows == 1 ? 1 : 0,
                  lg: testQuestion?.rows == 1 ? 1 : 0
                }
              },
              '&:nth-last-child(1)': {
                ml: {
                  md: testQuestion?.rows == 1 ? 1 : 0,
                  lg: testQuestion?.rows == 1 ? 1 : 0
                }
              }
            }}
          >
            {isSubmitted && (
              <Box
                className="Overlay"
                sx={{
                  backgroundColor: 'rgba(0,0,0,0.04)',
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  borderRadius: '12px'
                }}
              ></Box>
            )}
            <Box
              sx={{
                boxSizing: 'border-box',
                cursor: !isSubmitted ? 'pointer' : 'default',
                backgroundColor:
                  (selectedItem === choice.item) ? 'blue.100' : '#FAFAFA',
                width: {
                  xs: '90%',
                  sm: '90%',
                  md: testQuestion?.rows == 1 ? '100%': '100%',
                  lg: testQuestion?.rows == 1 ? '100%': '100%'
                },
                height: choice?.height || '100%',
                borderRadius: '12px',
                borderWidth: '3px',
                borderColor: 
                  (selectedItem === choice.item) ? 'info.main' : 'grey.50',
                borderStyle: 'solid',
                mx: 'auto',
                display: 'flex',
                alignItems: 'center',

                '&:hover': {
                  borderColor: 
                    (!isSubmitted && selectedItem !== choice.item) ? 'grey.200' : ''
                }
              }}
            >
              {(isSubmitted && choice.explanation) && (
                <Explanation choice={choice} />
              )}
              <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {lazyLoading && (
                    <Skeleton 
                      variant="rectangular" 
                      width={400} 
                      height={200}
                      sx={{
                        ml: 2
                      }} 
                    />
                  )}
                  <img
                    // @ts-ignore
                    ref={imageEl}
                    src={choice.url}
                    onLoad={handleImageLoad}
                    style={{
                      width: choice.width || '100%',
                      margin: '0 auto'
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      ))}
    </Box>
  );
};

export default Choices;