import { UserProvider } from "./User"
import { ProfileProvider } from "./Profile"
import { AlertProvider } from "./Alert"
import { DisplayProvider } from "./Display"
import { combineComponents } from "utils/combineComponents"
import { TestProvider } from "./Test"
import { PaymentProvider } from "./Payment"

const providers = [
  AlertProvider,
  PaymentProvider,
  DisplayProvider,
  TestProvider,
  ProfileProvider,
  UserProvider
]
const AppContextProvider = combineComponents(...providers);
export default AppContextProvider