import React, { useEffect } from "react"
import AuthNavigation from "components/Navigations/AuthNavigation"
import Alert from "components/Alerts/Alert"
import useAlert from "contexts/Alert"
import { Outlet } from "react-router-dom"
import useUser from "contexts/User"
import { CircularProgress } from "@mui/material"
import FooterNavigation from "components/Navigations/FooterNavigation"
import ThankYouDialog from "components/Globals/ThankYouDialog"
import usePayment from "contexts/Payment"

const AuthLayout: React.FC<any> = () => {
  const { showAlert } = useAlert();
  const { loadingPage } = useUser();
  const { showSuccessPayment, setShowSuccessPayment } = usePayment();

  return (
    <>
      <AuthNavigation />
      {showAlert && <Alert />}
      {showSuccessPayment && (
        <ThankYouDialog 
          onClose={() => setShowSuccessPayment(false)}
        />
      )}
      <Outlet />
    </>
  )
}

export default AuthLayout;