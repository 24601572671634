import { Box } from "@mui/material";
import React, { FC, useEffect } from "react";
import Header from "./components/Header";

import { updatePath } from "contexts/Paths";
import useProfile from "contexts/Profile";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { PATHS_DATA } from "statics/paths";
import style from "./body.module.scss";

interface Props {
  body: any;
  type?: any;
  pathId: string;
}

const Body: FC<Props> = ({ body, type = null, pathId }) => {
  const navigate = useNavigate();
  const { addProfilePath } = useProfile();

  // path mutation
  const { mutate: mutatePath, isLoading } = useMutation({
    mutationFn: ({ pathId, contents = [], read = false }: any) =>
      updatePath({ pathId, contents, read }),
    onSuccess: (data) => {
      console.log(data);
    },
    onError: (e) => {
      console.log(e, "Error in saving question");
    },
  });

  useEffect(() => {
    addProfilePath({
      data: PATHS_DATA[parseInt(pathId) - 1],
    });
  }, []);

  return (
    <Box sx={{ minHeight: "120vh", pb: "200px" }}>
      <Box>
        <Header onClose={() => navigate("/paths")} />
      </Box>
      <Box className={style.BodyContent}>
        {type === "COMPONENT" ? (
          <Box
            sx={{
              mt: 8,
              width: "800px",
              mx: "auto",
            }}
          >
            {body}
          </Box>
        ) : (
          <Box
            sx={{
              mt: 8,
              width: "800px",
              mx: "auto",
            }}
            dangerouslySetInnerHTML={{ __html: body }}
          ></Box>
        )}
      </Box>
    </Box>
  );
};

export default Body;
