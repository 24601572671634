import { createContext, useReducer, useContext, useMemo } from "react"
import { postRequest, getRequest } from "utils/api";
import paymentReducer, { initialState } from "./reducer"

const PaymentContext = createContext();

export const PaymentProvider = ({ children }) => {
  const [state, dispatch] = useReducer(paymentReducer, initialState);

  const dispatchAlert = ({ type, title, message }) => {
    dispatch({
      type,
      payload: {
        title,
        message
      }
    })
  }

  const setShowSuccessPayment = (val) => {
    dispatch({ 
      type: "TOGGLE",
      payload: val
    })
  }

  // VALUE context prop
  const value = useMemo(() => {
    return {
      dispatchAlert,
      type: state.type,
      showSuccessPayment: state.showSuccessPayment,
      setShowSuccessPayment,
      title: state.title,
      message: state.message
    }
  }, [
    dispatchAlert,
    setShowSuccessPayment,
    state.type,
    state.showSuccessPayment,
    state.title,
    state.message
  ])

  return (
    <PaymentContext.Provider value={value}>
      {children}
    </PaymentContext.Provider>
  )
}

export const storePayment = async (data) => {
  const payload = {
    url: `/payment`,
    data
  };
  const res = await postRequest(payload);
  return res.data;
};

export const getPayment = async () => {
  const payload = {
    url: `/payment`
  };
  const res = await getRequest(payload);
  return res.data;
};

const usePayment = () => {
  const context = useContext(PaymentContext)
  if (context === undefined) {
    throw new Error("usePayment must be used within Payment");
  }
  return context;
}

export default usePayment;