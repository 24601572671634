import useAlert from "contexts/Alert";
import React, { FC } from "react";
// import CloseIcon from "../Icons/CloseIcon"
// import CheckIcon from "../Icons/CheckIcon"
// import ErrorIcon from "../Icons/ErrorIcon"
import { Alert, IconButton, Snackbar } from "@mui/material";

const AlertBar: FC = () => {
  const { type, message, dispatchAlert } = useAlert();

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatchAlert({
      type: "REMOVE",
    });
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        {/* <CloseIcon /> */}
      </IconButton>
    </>
  );

  return (
    <Snackbar
      open={type ? true : false}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={type === "SUCCESS" ? "success" : "error"}
        sx={{
          width: "100%",
          backgroundColor: type === "SUCCESS" ? "grey.800" : "grey.800",
          color: "#FFF",
          p: 2,
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertBar;
