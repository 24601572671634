import React from "react";
import { styledFlexWrapper } from "styles";
import styles from "./Footer.module.scss";
import ThemeWrapper from "components/ThemeWrapper";
import { Box, Typography, Button } from '@mui/material';
import { ReactComponent as MainLogo } from "assets/mainLogo.svg"

const FooterNavigation:React.FC = () => {
  return (
    <ThemeWrapper>
      <Box
        sx={{
          ...styledFlexWrapper,
          backgroundColor: '#fafafa',
          borderTop: '1px solid #eaeaea',
          borderBottom: '1px solid #eaeaea'
        }}
        padding="1em"
        minHeight="0"
      >
        <Box
          sx={{
            maxWidth: '1140px',
            m: 'auto',
          }}
        >
            <div className={styles.FooterWrapper}>
              <Box
                sx={{
                  textAlign: {
                    xs: 'left',
                    md: 'center',
                    lg: 'center'
                  },
                  borderRadius: 1,
                }}
              >
                <Box
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '&:hover': {
                      backgroundColor: 'transparent'
                    }
                  }}
                >
                <Box
                  sx={{
                    width: '30px',
                    height: '30px',
                    pl: 0.8,
                    backgroundImage: 'radial-gradient(145.05% 100% at 50% 0,#1d2b41 0,#0b1627 57.38%,#142133 88.16%)',
                    boxShadow: '0 5px 12px rgba(0,0,0,.12)',
                    pr: 0.7,
                    borderRadius: '12px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <MainLogo />
                </Box>
                <Box sx={{ ml: 1.5 }}>
                  <Typography variant="h5" fontSize={16}>OneGuru</Typography>
                </Box>
              </Box>
                <Typography variant="body2" sx={{ mt: 4 }}>
                  © OneGuru Platform, Inc. All Rights Reserved.
                </Typography>
              </Box>
            </div>
        </Box>
      </Box>
    </ThemeWrapper>
  )
}

export default FooterNavigation;