import { Path } from "utils/apiTypes";

interface Props {
  pathLabel?: string;
};

export const usePath = ({
  pathLabel
}: Props) => {
  
  // @ts-ignore
  const paths = JSON.parse(localStorage.getItem("paths"));
  let path = paths.filter((path: Path) => path.label === pathLabel);
  path = path[0];

  return {
    paths,
    path
  }
}