import React, { FC, useState } from "react";
import { Box,Dialog, DialogContent, Divider, DialogContentText, DialogTitle, DialogActions, Button, Typography, IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import useUser from 'contexts/User';
import { ReactComponent as ButtonPanaIcon } from "assets/images/button-pana.svg"
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as DogIcon } from "assets/images/dog-paw.svg"

interface Props {
  onClose: () => void;
};

const WelcomeDialog = ({
  onClose
}: Props) => {
  const navigate = useNavigate();
  const { userDetails } = useUser();

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 12,
        m: {
          xs: 2,
          sm: 2,
          md: 4,
          lg: 4
        },
        left: 0,
        bottom: 0,
      }}
    >
      <DogIcon
        style={{
          width: '100px',
          marginBottom: -15,
          zIndex: 10,
          position: 'relative'
        }}
      />
      <Box 
        sx={{ 
          width: {
            xs: '100%',
            sm: '100%',
            md: '450px',
            lg: '450px'
          }, 
          background: '#12B76A',
          color: '#FFF',
          zIndex: 11,
          position: 'relative',
          p: 2,
          borderRadius: 4
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h4">
            {/* @ts-ignore */}
            Welcome, {localStorage.getItem("firstName")}!
          </Typography>
          <IconButton
            onClick={() => onClose()}
            sx={{ p: 0, color: '#FFF' }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography 
          variant="body2" 
          fontWeight={400} 
          fontSize={15}
          sx={{ mt: 1 }}
        >
          Kindly read the questions carefully before answering to ensure that you have complete understanding of the context. Good luck!
        </Typography>
      </Box>
    </Box>
  );
};

export default WelcomeDialog;