import React, { FC, useState } from "react";
import {
  Box, Button, TextField, Typography, Card, CardMedia, CardContent,
  Rating
} from "@mui/material";

const PaginationClip: FC = () => {
  const [products, setProducts] = useState([
    {
      name: "Nike running shoes red",
      price: "$400",
      image: "https://images.unsplash.com/photo-1542291026-7eec264c27ff?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8c2hvZXN8ZW58MHx8MHx8&w=1000&q=80"
    },
    {
      name: "Vans vault skate shoes red",
      price: "$300",
      image: "https://images.unsplash.com/photo-1525966222134-fcfa99b8ae77?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=796&q=80"
    },
    {
      name: "Vans vault skate shoes red",
      price: "$300",
      image: "https://images.unsplash.com/photo-1525966222134-fcfa99b8ae77?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=796&q=80"
    },
    {
      name: "Vans vault skate shoes red",
      price: "$300",
      image: "https://images.unsplash.com/photo-1525966222134-fcfa99b8ae77?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=796&q=80"
    }
  ])

  return (
    <Box>
      {/* <Box 
        sx={{ 
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          p: 3,
          borderBottom: "1px solid black"
        }}
      >
        <Typography>Logo</Typography>
        <Box
          sx={{ 
            width: "100%",
            display: "flex",
            ml: 30,
            justifyContent: "space-between"
          }}
        >
          <Box>
            <TextField 
              size="small"
              placeholder="Search for a product"
              sx={{
                width: "700px"
              }}
            />
          </Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "info.main"
            }}
          >Login</Button>
        </Box>
      </Box> */}

      {/* BODY */}
      <Box
        sx={{
          mt: 2
        }}
      >
        {/* HEADER */}
        <Box sx={{ mb: 3, m: 1 }}>
          <Typography variant="h3">
            For Sale
          </Typography>
        </Box>
        <Box sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%"
        }}>
          {products.map(product => (
            <Card sx={{ m: 1, overflow: "hidden", width: "45%" }}>
              <CardMedia 
                component="img"
                image={product.image}
                sx={{
                  height: "150px",
                  width: "100%",
                  overflow: "hidden"
                }}
              />
              <CardContent>
                <Typography variant="body2">
                  {product.name}
                </Typography>
                <Typography variant="h4" sx={{ mt: 1 }}>
                  {product.price}
                </Typography>
                <Rating 
                  name="read-only" 
                  value={100} 
                  readOnly
                  sx={{ mt: 1 }}
                />
              </CardContent>
            </Card>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default PaginationClip;