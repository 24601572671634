import React, { FC, useState } from "react";
import { Tooltip, Box, Button, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import StyledChip from "components/Globals/StyledChip";
import TextDialog from "./TextDialog";
import { Path, Question } from "utils/apiTypes";

interface Props {
  pathLabel?: string;
};

const ReviewResult: FC<any> = ({
  pathLabel
}) => {
  // @ts-ignore
  const paths = JSON.parse(localStorage.getItem("paths"));
  const path = paths.filter((path: Path) => path.label === pathLabel);
  const rows = path[0]?.contents;

  const [showTextDialog, setShowTextDialog] = useState<boolean>(false);
  const [textDialogTitle, setTextDialogTitle] = useState<string>("");
  const [textDialogDescription, setTextDialogDescription] = useState<string>("");
  const [textDialogImage, setTextDialogImage] = useState<string>("");

  const [currentViewedQuestion, setCurrentViewedQuestion] = useState<Question>({
    choices: [],
    correctAnswer: "",
    difficulty: "",
    feedback: "",
    label: "",
    rows: 0,
    selectedAnswer: ""
  });

  const columns = [
    {
      field: "question",
      headerName: "Questions",
      sortable: false,
      flex: 4,
      renderCell: (params: any) => {
        return (
          <>
            <Typography variant="body2" sx={{ ml: 2 }}>
              {params.row.question.label}
            </Typography>
            {/* <Tooltip 
              title={
                <Typography variant="body2">
                  {params.row.question}
                </Typography>
              }
            >
              <Typography
                noWrap
                variant="body2"
                sx={{ ml: 2 }}
              >
                {params.row.question}
              </Typography>
            </Tooltip> */}
          </>
        );
      },
    },
    {
      field: "selectedAnswer",
      headerName: "Answers",
      sortable: false,
      renderCell: (params: any) => {
        return (
          <>
            {params.row.question.selectedAnswer === params.row.question.correctAnswer ? (
              <CheckIcon sx={{ ml: 1, color: 'green.400'}} />
            ) : (
              <CloseIcon sx={{ ml: 1, color: 'red.400'}} />
            )}
          </>
        );
      },
    },
    {
      field: "difficulty",
      headerName: "Difficulty",
      sortable: false,
      renderCell: (params: any) => {
        return (
          <StyledChip label={params.row.question.difficulty} />
        );
      },
    },
    {
      field: "answer",
      headerName: "Correct answers",
      sortable: false,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Button
            variant="outlined"
            onClick={() => {
              setShowTextDialog(true)
              setCurrentViewedQuestion(params.row.question)
            }}
          >
            View Answer
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Box 
        sx={{ 
          height: 690, 
          width: '100%',
          border: '0px solid #FFF'
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          hideFooter
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          sx={{
            boxShadow: 0,
            pl: 2,
            pr: 2,
            '&:focus': {
              outline: 'none'
            },
            '.MuiDataGrid-row:nth-child(even)': {
              backgroundColor: 'grey.50',
              border: '1px solid #FFF',
              '&:focus': {
                outline: 'none'
              }
            },
            '&.MuiDataGrid-row:hover': {
              backgroundColor: 'grey.100'
            },
            '&.MuiDataGrid-cell': {
              '&:focus': {
                outline: 'none'
              }
            },
            borderColor: '#FFF'
          }}
        />
        {showTextDialog && (
          <TextDialog
            title={textDialogTitle}
            question={currentViewedQuestion}
            description={textDialogDescription}
            imageUrl={textDialogImage}
            onClose={() => setShowTextDialog(false)}
          />
        )}
      </Box>
    </>
  );  
};

export default ReviewResult;