import React, { FC, useState } from "react";
import { Dialog, Box, DialogContent, Divider, DialogContentText, DialogTitle, DialogActions, Button, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as DogIcon } from "assets/images/dog-paw.svg"
import BannerImage from "assets/images/membership.png";
import MediaRight from "assets/images/MediaLibraryRight.png";
import MediaWrong from "assets/images/MediaLibraryWrong.png";

interface Props {
  onClose?: () => void;
};

const BookModal = ({
  onClose
}: Props) => {
  const navigate = useNavigate();
  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth={"xs"}
      onClose={onClose}
      PaperProps={{
        style: {
          maxWidth: "1000px",
          padding: 0,
          minHeight: "700px"
        },
      }}
    >
      <Box sx={{ p: 4 }}>
        <Typography variant="body2" fontSize={14} fontWeight={14}>
          page 12
        </Typography>
        <Typography variant="h1" fontWeight={600} sx={{ mt: 1 }}>
          UX for handling bulk actions
        </Typography>
        <Typography variant="body2" fontSize={16} sx={{ mt: 2 }} fontWeight={400}>
          When it comes to bulk actions, we must consider how much time it’ll take to finish all the action requests. In this case, we have to build a design in which users can still use the system while waiting for the bulk action to finish. Granted that the system still has an ongoing request, it should not hinder the users' freedom to use the other parts of the system.
        </Typography>
        <Typography variant="body2" fontSize={16} fontWeight={400} sx={{ mt: 3 }}>
          For instance, a user performs a bulk delete action on multiple images within the media library.
        </Typography>
        <img src={MediaWrong} style={{ width: '700px'}} />
        <Typography variant="body2" fontSize={16} fontWeight={400} sx={{ mt: 2 }}>
          If the user wishes to go to a different page, the only option they have at this point is to open a new browser tab.
        </Typography>
        <Typography variant="body2" fontSize={16} fontWeight={400} sx={{ mt: 2, mb: 2 }}>
          To ensure that we don’t force them to do that, we must create a design that allows them to freely use the app while still performing a bunch of delete requests at the same time.
        </Typography>
        <img src={MediaRight} style={{ width: '700px' }} />
      </Box>
    </Dialog>
  );
};

export default BookModal;