import React, { FC } from "react";
import { Chip, Typography } from "@mui/material";

interface Props {
  label: string;  
};

const StyledChip: FC<Props> = ({ label }) => {
  return (
    <Chip 
      label={
        <Typography 
          variant="overline"
          sx={{
            color: '#FFF'
          }}
        >
          {label}
        </Typography>
      } 
      sx={{
        background: 
          label === 'Easy' ? 'linear-gradient(90deg,#007CF0,#007CF0)' : 
          label === 'Medium' ? 'linear-gradient(290deg,#ff4d4d,#ff4d4d)' : 
          label === 'Hard' ? '#FF0080' :
          label === 'Ebook' ? '#FF0080' :
          label === 'Full stack' ? 'linear-gradient(90deg,#007CF0,#FF0080)' : 
          label === 'Frontend' ? 'linear-gradient(140deg,#7928CA,#FF0080)' : 
          label === 'Backend' ? 'linear-gradient(290deg,#ff4d4d,#ff4d4d)' :
          '#10182814',
        color: '#FFF',
        fontWeight: 600,
        fontSize: '12px',
        height: '20px',

        '.MuiChip-label': {
          px: '5px'
        }
      }}
    />
  );
};

export default StyledChip;