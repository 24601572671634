import React, { createContext, useContext, useMemo, useReducer, useState } from 'react'

const DisplayContext = createContext<any>("");

export const DisplayProvider: React.FC<any> = ({ children }) => {
  const [displayMode, setDisplayMode] = useState<any>("LIGHT")

  const updateDisplayMode = (val: string) => {
    setDisplayMode(val)
  }

  const value = useMemo(() => {
    return {
      updateDisplayMode,
      displayMode
    }
  }, [updateDisplayMode, displayMode])

  return (
    <DisplayContext.Provider value={value}>
      {children}
    </DisplayContext.Provider>
  )
}

const useDisplay = () => {
  const context = useContext(DisplayContext)
  if (context === undefined) {
    throw new Error("useDisplay must be used within DisplayContext")
  }
  return context
}

export default useDisplay;