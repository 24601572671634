import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, CircularProgress, Typography } from "@mui/material";
import { useQuery } from "react-query";

import { Path } from "utils/apiTypes";
import BodyNotif from "./BodyNotif";
import ResultNotif from "./ResultNotif";
import StartNow from "./StartNow";

import LockIcon from "@mui/icons-material/Lock";
import useProfile from "contexts/Profile";
import HierarchyIcon from "../../assets/images/hierarchy-icon.png";
import ModalIcon from "../../assets/images/modal-icon.png";
import RedesigningUIIcon from "../../assets/images/redesigning-ui-icon.png";
import UXBook from "../../assets/images/ux-book.png";

import { PATHS_DATA } from "../../statics/paths";
import { ReactComponent as HouseIcon } from "./icons/house.svg";
import { ReactComponent as IceIcon } from "./icons/iceIcon.svg";

interface PathIcon {
  [key: string]: any;
}

const pathIcons: PathIcon = {
  redesigning_ui: <img src={RedesigningUIIcon} style={{ marginLeft: -7 }} />,
  creating_contrast: <img src={RedesigningUIIcon} style={{ marginLeft: -7 }} />,
  spacing_and_alignments: (
    <img src={RedesigningUIIcon} style={{ marginLeft: -7 }} />
  ),
  typography: <img src={RedesigningUIIcon} style={{ marginLeft: -7 }} />,
  free_test: <HouseIcon />,
  popups_modals_and_dialogs: <img src={ModalIcon} style={{ marginLeft: -7 }} />,
  mental_models: <img src={UXBook} style={{ marginTop: -20, width: "70px" }} />,
  automated_way_of_onboarding_users: (
    <img src={UXBook} style={{ marginTop: -20, width: "70px" }} />
  ),
  selection_ux: <img src={UXBook} style={{ marginTop: -20, width: "70px" }} />,
  identify_primary_actions_and_sections: (
    <img src={HierarchyIcon} style={{ marginLeft: -7 }} />
  ),
};

export const Paths: FC = () => {
  const navigate = useNavigate();
  const [currentPathId, setCurrentPathId] = useState<string>("");
  const { getProfile } = useProfile();

  // @ts-ignore
  const membershipDetails = localStorage.getItem("membershipDetails");
  const [paths, setPaths] = useState(PATHS_DATA);

  // fetch profile data to get the paths inside
  const {
    isLoading: isLoadingProfile,
    error,
    data: profile = null,
  } = useQuery("profile", async () => {
    if (localStorage.getItem("userId") && localStorage.getItem("accessToken")) {
      const res = await getProfile({
        id: localStorage.getItem("userId"),
      });
      return res.data.data;
    }
  });

  // set initial paths data
  useEffect(() => {
    localStorage.setItem("paths", JSON.stringify(paths) || "[]");
    paths?.every((path: Path, key: number) => {
      if (!path.totalResult) {
        setCurrentPathId(path.id);
        return false;
      }
      return true;
    });
  }, [paths]);

  useEffect(() => {
    if (profile) {
      const newPaths = paths?.map((path: any) => {
        const matchingProfilePath = profile.paths.find(
          (profilePath: any) => path.id === profilePath.id
        );

        if (matchingProfilePath) {
          return {
            ...path,
            totalResult:
              matchingProfilePath.totalCorrectAnswers === 0
                ? 1
                : matchingProfilePath.totalCorrectAnswers,
          };
        }

        return path;
      });
      setPaths(newPaths);
    }
  }, [profile]);

  return (
    <Box>
      {paths?.length && !isLoadingProfile ? (
        <>
          {paths.map((path: Path, key: any) => (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "75%",
                }}
              >
                {key % 2 !== 0 && <Box></Box>}
                <Box>
                  {path.id === currentPathId && !path.totalResult ? (
                    <Box sx={{ position: "absolute", ml: -10 }}>
                      {/* {!paymentDetails?._id && path.label !== "free_test" ? (
                        <UnlockNotif
                          onStart={() => navigate(`/question/${path.label}/1`)}
                          pathName={path.name}
                        />
                      ) : (
                        <StartNow
                          onStart={() => navigate(`/question/${path.label}/1`)}
                          pathName={path.name}
                        />
                      )} */}
                      <StartNow
                        onStart={() => navigate(`/question/${path.label}/1`)}
                        pathName={path.name}
                      />
                    </Box>
                  ) : path.totalResult && !path?.contents[0].body ? (
                    <Box sx={{ position: "absolute", ml: -13 }}>
                      <ResultNotif
                        pathLabel={path.label}
                        pathName={path.name}
                        pathTotalResult={path.totalResult}
                      />
                    </Box>
                  ) : path.totalResult && path?.contents[0].body ? (
                    <Box sx={{ position: "absolute", ml: -13 }}>
                      <BodyNotif
                        pathLabel={path.label}
                        pathName={path.name}
                        pathTotalResult={path.totalResult}
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        alignItems: "center",
                        position: "absolute",
                        ml: -3,
                      }}
                    >
                      <Box
                        sx={{
                          py: 2,
                          px: 2,
                          justifyContent: "space-between",
                          backgroundColor: "#FFF",
                          minWidth: "150px",
                          zIndex: 10,
                          borderRadius: 4,
                          alignItems: "center",
                          boxShadow: "0 15px 40px rgba(0,0,0,0.1)",
                        }}
                      >
                        <Box>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            textAlign="center"
                            fontSize={15}
                          >
                            {path.name}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  <Box>
                    <Box
                      sx={{
                        position: "relative",
                        mt:
                          path.id === currentPathId && !path.totalResult
                            ? 17
                            : path.totalResult
                            ? 21
                            : 12, // adjust margin if notif is displayed
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          zIndex: 11,
                          mx: 2,
                          mt: -1,
                        }}
                      >
                        {path.label === "free_test" ? (
                          pathIcons[path.label]
                        ) : membershipDetails ? (
                          pathIcons[path.label]
                        ) : (
                          <LockIcon
                            sx={{
                              color: "text.secondary",
                              ml: 1,
                              fontSize: 48,
                            }}
                          />
                        )}
                      </Box>
                      <Box
                        sx={{
                          mt: 5,
                          borderRadius: 2,
                          zIndex: 10,
                          px: 2,
                          position: "relative",
                          mx: "auto",
                          width: "100px",
                          height: "100px",
                          display: "flex",
                          alignItems: "center",
                          transform: "rotateX(45deg) rotateZ(45deg)",
                          background:
                            "radial-gradient(circle at 30px 100px, rgb(174, 174, 178), #0000000f)",
                          backgroundColor: "#FFF",
                          borderBottom: "12px solid rgb(174, 174, 178)",
                          borderRight: "12px solid rgb(209, 209, 214)",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        transform: "rotate(540deg)",
                        mt: -12,
                        ml: -1,
                        zIndex: 5,
                        position: "absolute",
                      }}
                    >
                      <IceIcon />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </>
      ) : (
        <Box sx={{ mt: 10 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};
