import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";
import React, { useLayoutEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import AuthRoutes from "./components/AuthRoutes";
import AppContextProvider from "./contexts";
import "./styles/global.scss";
import palette from "./utils/palette";
import typography from "./utils/typography";

// pages
import GithubAccess from "pages/auth-access";
import ContentWrapper from "pages/content";
import Signup from "./pages/login";
import Masthead from "./pages/masthead";
import Home from "./pages/paths";
import ComingSoon from "./pages/paths/ComingSoon";
import Profile from "./pages/profile";

// clips

// layouts
import LayoutHome from "pages/clips/Layout";
import SecondLayoutClip from "pages/clips/Layout/Second";
import MenuHome from "pages/clips/Menu";
import PaginationClip from "pages/clips/PaginationClip";
import Pricing from "pages/pricing";
import { AuthLayout, UnauthLayout } from "./components/Layouts";

const theme: Theme = createTheme({
  palette,
  typography,
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          border: "solid 1px #EAEAEA",
          boxShadow: "0 2px 4px #33300014",
          "&:hover": {
            boxShadow: "0 5px 12px #e8e8e8",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          boxShadow: "0 8px 30px rgba(0,0,0,.12)",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "none",
          },
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          ".MuiBox-root": {
            borderRadius: "13px",
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          boxShadow: "none",

          "&:hover": {
            opacity: 0.9,
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: "18px",
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "20px 24px",
        },
      },
    },
  },
});

const App: React.FC = () => {
  const location = useLocation();

  // Auto scroll to top when path changes
  // SPA doesn't automatically do this because it doesn't refresh on every reroute
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <AppContextProvider>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route element={<UnauthLayout />}>
              <Route index element={<Masthead />} />
            </Route>
            {/* <Route element={<AuthLayout />}> */}
            <Route element={<AuthRoutes />}>
              <Route path="/paths" element={<Home />} />
            </Route>
            {/* </Route> */}
          </Routes>
          <Routes>
            <Route path="/layout-home" element={<LayoutHome />} />
            <Route path="/second-layout" element={<SecondLayoutClip />} />
            <Route path="/pagination" element={<PaginationClip />} />
            <Route path="/menu-home" element={<MenuHome />} />
            <Route path="/login" element={<Signup />} />
            {/* UNAUTH ROUTES */}
            <Route path="/auth-access" element={<GithubAccess />} />
            <Route path="/pricing" element={<Pricing />} />

            {/* AUTH ROUTES */}
            <Route element={<AuthRoutes />}>
              <Route
                path="/question/:label/:contentId"
                element={<ContentWrapper />}
              />
            </Route>
            {/* AUTH LAYOUT */}
            <Route element={<AuthLayout />}>
              <Route element={<AuthRoutes />}>
                <Route path="/profile/:id" element={<Profile />} />
                <Route path="/coming-soon" element={<ComingSoon />} />
              </Route>
            </Route>
          </Routes>
        </ThemeProvider>
      </AppContextProvider>
    </>
  );
};

export default App;
