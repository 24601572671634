import React, { useState, FC, useRef } from "react";
import BannerImage from "assets/images/ecom.png";
import { useNavigate } from "react-router-dom";
import { Button, Typography, Divider, Dialog } from "@mui/material";
import Box from '@mui/material/Box';
import useDisplay from "contexts/Display";
import Animate from "components/Animate";
import ThemeWrapper from "components/ThemeWrapper";
import { styledFlexWrapper } from "styles";
import EditorDisplay from "components/Globals/EditorDisplay";
import useUser from "contexts/User";

const LayoutHome: FC = () => {
  const navigate = useNavigate();
  const { displayMode } = useDisplay();
  const { userDetails } = useUser();
  const imageEl = useRef<HTMLImageElement>();
  const [lazyLoading, setLazyLoading] = useState<Boolean>(true);
  const [showDialog, setShowDialog] = useState(false);

  const handleImageLoad = () => {
    setLazyLoading(false);
  };
  
  return (
    <Box
      sx={{
        ...styledFlexWrapper,
        background: '#FFF',
        // background: 'linear-gradient(180deg,transparent,hsla(0,0%,95%,.29) 47.39%,#fff 65%),linear-gradient(90deg,#f9ddf1 .07%,#e5e1ff 16.73%,#daedff 34.48%)',
        // backgroundImage: 'radial-gradient(145.05% 100% at 50% 0,rgb(243, 242, 255) 0,#f7f9fd 57.38%,#FFF 88.16%)',
        pb: 5,
        minHeight: "0"
      }}
    >
      <Box
        sx={{
          backgroundColor: '#FFF',
          px: 12,
          py: 3,
          display: 'flex',
          boxShadow: '0 2px 4px #33300014',
        }}
      >
        <Typography
          sx={{
            color: 'deepOrange.500',
            fontWeight: 800,
            fontSize: 32
          }}
        >
          GoBuild.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            pl: 6
          }}
        >
          <Box sx={{ display: 'flex', pt: 1.5 }}>
            <Typography>
              Pricing
            </Typography>
            <Typography ml={4}>
              Templates
            </Typography>
            <Typography ml={4}>
              Documentation
            </Typography>
            <Typography ml={4}>
              Get help
            </Typography>
          </Box>
          <Button
            variant="outlined"
          >
            Sign In
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          maxWidth: {
            xs: '100%',
            sm: '100%',
            md: '1300px',
            lg: '1300px'
          },
          margin: "auto"
        }}
      >
        <Animate type="fade-up" showOnScroll={false}>
          <Box
            sx={{
              display: 'flex',
              overflow: 'hidden',
              flexDirection: {
                xs: 'column',
                md: 'row'
              },
              justifyContent: 'space-between',
              borderRadius: 1,
              pt: 5,
              pb: 15
            }}
          >
            <Box 
              sx={{ 
                display: {
                  xs: 'block',
                  md: 'flex',
                  lg: 'flex'
                },
                '@media screen and (max-width: 900px)': {
                  mt: 7
                },
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '50%',
                  lg: '50%'
                },
                justifyContent: 'center', 
                alignItems: 'center'
              }}
            >
              <ThemeWrapper>
                <Typography
                  variant="h1"
                  sx={{
                    mb: 2,
                    fontSize: {
                      xs: 32,
                      sm: 32,
                      md: 46,
                      lg: 46
                    },
                    '@media screen and (max-width: 900px)': {
                      mt: 0,
                    },
                  }}
                >
                  Build your e-commerce business with us
                </Typography>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    fontWeight: 400,
                    color: '#2B2C33',
                    fontSize: {
                      xs: 18,
                      sm: 18,
                      md: 20,
                      lg: 20
                    },
                    width: {
                      xs: '100%',
                      sm: '100%',
                      md: '80%',
                      lg: '80%'
                    },
                  }}
                >
                  We can help you get started with your e-commerce venture. We offer many features including website creation, store manager, and more.
                </Typography>
                <Button
                  type="button"
                  color="primary"
                  disableElevation
                  variant="outlined"
                  onClick={() => {
                    // @ts-ignore
                    // if(localStorage.getItem("accessToken")) {
                    //   navigate('/');
                    // } else {
                    navigate('/login');
                    // }
                  }}
                  size="large"
                  sx={{
                    mt: 3,
                    fontWeight: 600,
                    textTransform: "none",
                    fontSize: 16
                  }}
                >
                  Learn more
                </Button>
                <Button
                  type="button"
                  color="primary"
                  disableElevation
                  variant="contained"
                  onClick={() => {
                    // @ts-ignore
                    // navigate('/second-layout');
                    setShowDialog(true)
                  }}
                  size="large"
                  sx={{
                    ml: 1.5,
                    mt: 3,
                    fontWeight: 600,
                    textTransform: "none",
                    fontSize: 16
                  }}
                >
                  Start Now
                </Button>
              </ThemeWrapper>
            </Box>
            
            {/* Banner Image */}
            <Box 
              sx={{ 
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '55%',
                  lg: '55%'
                },
                mt: {
                  xs: 8,
                  sm: 8,
                  md: 0,
                  lg: -9
                }
              }}
            >
              {lazyLoading && (
                <Box
                  sx={{
                    backgroundColor: 'transparent',
                    width: '900px',
                    height: '450px',
                    position: 'relative',
                    zIndex: 20
                  }}
                >
                </Box>
              )}
              <Box
                component="img"
                src={BannerImage}
                ref={imageEl}
                onLoad={handleImageLoad}
                sx={{
                  zIndex: 15,
                  position: 'relative',
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '100%',
                    lg: '100%'
                  },
                }}
              ></Box>
            </Box>
            
            {showDialog && (
              <Dialog 
                open={true}
                PaperProps={{
                  style: {
                    minWidth: "730px",
                    padding: "50px 50px 60px 50px"
                  }
                }}
                onClose={() => setShowDialog(false)}
              >
                <Box>
                  <Typography variant="body2" fontSize={24}>
                    Step 1 of 2
                  </Typography>
                  <Typography variant="h1" mt={2}>
                    Choose your preferred template
                  </Typography>
                  <Typography variant="body1" mt={2} sx={{ width: "70%" }} color="text.secondary" fontWeight={400}>
                    By choosing a template, you'll gain access to its built in features and settings.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    pt: 5,
                    display: 'flex',
                  }}
                >
                  <Box
                    sx={{
                      border: '1px solid #dbdbdb',
                      width: '300px',
                      borderRadius: '8px',
                      mr: 2,
                      overflow: "hidden",
                      cursor: 'pointer',
                      '&:hover': {
                        border: '1px solid #7430a1'
                      }
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: '#E1BEE7',
                        padding: "1 2.5 1 2.5",
                        textAlign: 'center',
                        borderTopRightRadius: "8px",
                        borderTopLeftRadius: "8px"
                      }}
                    >
                      <img 
                        src={require('../../../assets/images/grocery.png')} 
                        width={150}
                        height={150}
                      />
                    </div>
                    <Box p={2.5}>
                      <Typography variant="h4">
                        Grocery store
                      </Typography>
                      <Typography variant="body1" color="text.secondary" mt={1.5} fontWeight={400}>
                        A template that has the complete features of an online grocery app.
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      border: '1px solid #dbdbdb',
                      width: '300px',
                      borderRadius: '8px',
                      overflow: "hidden",
                      mr: 2,
                      cursor: 'pointer',
                      '&:hover': {
                        border: '1px solid #7430a1'
                      }
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: '#FEE4E2',
                        padding: "1 2.5 1 2.5",
                        textAlign: 'center',
                        borderTopRightRadius: "8px",
                        borderTopLeftRadius: "8px"
                      }}
                    >
                      <img 
                        src={require('../../../assets/images/clothing.png')} 
                        width={150}
                        height={150}
                      />
                    </div>
                    <Box p={2.5}>
                      <Typography variant="h4">
                        Clothing store
                      </Typography>
                      <Typography variant="body1" color="text.secondary" mt={1.5} fontWeight={400}>
                        A template that has the complete features of an online clothing store.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Dialog>
            )}
          </Box>
        </Animate>
      </Box>
    </Box>
  )
}

export default LayoutHome;