import React from "react"
import { Outlet } from "react-router-dom"
import UnauthNavigation from "components/Navigations/UnauthNavigation"
import FooterNavigation from "components/Navigations/FooterNavigation"

const UnauthLayout: React.FC<any> = () => {
  return (
    <>
      <UnauthNavigation />
      <Outlet />
      <FooterNavigation />
    </>
  )
}

export default UnauthLayout