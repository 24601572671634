export const styledPageWrapper = {
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'flex',
  maxWidth: '100%',
  minHeight: '100vh',
  '@media only screen and (max-width: 600px)': {
    padding: '2rem'
  }
}

export const styledFlexWrapper = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  zIndex: 50,
  width: '100%',
  position: 'relative',
  flexShrink: 1,
  flexBasis: 'auto',
  // p: {
  //   xs: 0,
  //   sm: 0,
  //   md: 5,
  //   lg: 17
  // }

  '@media only screen and (max-width: 600px)': {
    padding: '4rem 1.3rem'
  }
}

export const styledCard = {
  width: '270px',
  minHeight: '341px',
  maxHeight: '341px',
  padding: '2rem',
  textAlign: 'left'
}