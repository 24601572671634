import React, { FC, useEffect, useState } from "react";
import { Box, Typography, CircularProgress, Button, Dialog, Card, IconButton } from "@mui/material";
import ReviewResult from "components/Paths/ReviewResult";
import CloseIcon from '@mui/icons-material/Close';
import { keyframes } from '@mui/system';
import useTest from "contexts/Test";
import useUser from "contexts/User";
import testQuestions from "statics/testQuestions";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";

interface Props {
  onStart: any;
  pathName: string;
};

const StartNow = ({
  onStart,
  pathName
}: Props) => {
  const {updateAuditLog} = useUser();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const spin = keyframes`
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(0px);
    }
  `;

  return (
    <Box 
      sx={{
        alignItems: 'center',
        ml: 1,
        animation: `${spin} 1.5s infinite`
      }}
    >
      <Box
        sx={{
          py: 2,
          px: 2,
          justifyContent: 'space-between',
          // backgroundColor: '#091c27',
          backgroundColor: '#12B76A',
          minWidth: "150px",
          zIndex: 10,
          borderRadius: 4,
          alignItems: 'center',
          boxShadow: '0 15px 40px rgba(0,0,0,0.1)',
        }}
      >
        <Box>
          <Typography sx={{ color: '#FFF' }} variant="body2" textAlign="center" fontSize={15}>
            {pathName}
          </Typography>
          <Button 
            onClick={onStart}
            variant="contained" 
            sx={{
              width: '100%', 
              mt: 0.5, 
              fontSize: 12,
              color: 'green.500',
              backgroundColor: 'green.50',

              '&:hover': {
                backgroundColor: 'green.50',
              }
            }}
          >
            Start Now
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          width: 0,
          height: 0,
          position: 'relative',
          borderLeft: '15px solid transparent',
          borderRight: '15px solid transparent',
          borderTop: '15px solid #12B76A',
          mx: 'auto'
        }}
      ></Box>
    </Box>
  );  
}

export default StartNow;