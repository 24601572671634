import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { Path } from "utils/apiTypes";
import ContentBody from "./Body";
import Question from "./Question";

const Content: FC = () => {
  const params = useParams();

  // @ts-ignore
  const paths = JSON.parse(localStorage.getItem("paths"));
  let path = paths?.filter((path: Path) => path.label === params.label);
  if (path) {
    path = path[0];
  }
  let content = path?.contents?.filter(
    (content: any) => content.id === params.contentId
  );
  if (content) {
    content = content[0];
  }

  if (content?.body) {
    return (
      <ContentBody body={content.body} type={content.type} pathId={path.id} />
    );
  } else {
    return <Question key={params.contentId} />;
  }
};

export default Content;
