import React, { FC } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { ReactComponent as MainLogo } from "assets/mainLogo.svg";
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  onClose?: () => any;
};

const Header: FC<Props> = ({
  onClose
}) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 3, py: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            width: '30px',
            mt: -0.5,
            height: '30px',
            pl: 0.8,
            backgroundImage: 'radial-gradient(145.05% 100% at 50% 0,#1d2b41 0,#0b1627 57.38%,#142133 88.16%)',
            boxShadow: '0 5px 12px rgba(0,0,0,.12)',
            pr: 0.7,
            borderRadius: '12px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <MainLogo />
        </Box>
        <Box sx={{ ml: 1.5 }}>
          <Typography variant="h5" fontSize={16}>OneGuru</Typography>
        </Box>
      </Box>
      <IconButton 
        onClick={onClose}
      >
        <CloseIcon fontSize="large" />
      </IconButton>
    </Box>
  );
};

export default Header;