import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC } from "react"; 

const SecondLayoutClip: FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        backgroundColor: '#151217'
      }}
    >
      <Box
        sx={{
          width: "30%",
          backgroundColor: '#151217',
        }}
      >
        <Typography
          sx={{
            color: 'deepOrange.400',
            fontWeight: 800,
            fontSize: 24,
            p: 5
          }}
        >
          GoBuild.
        </Typography>
        <Box
          sx={{
            m: "auto",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            p: 5,
            pt: 20
          }}
        >
          <Typography variant="body2" fontSize={24} color="common.white">
            Step 1 of 2
          </Typography>
          <Typography color="common.white" variant="h1" fontSize={38} mt={2}>
            Choose your preferred template
          </Typography>
          <Typography color="common.white" variant="body1" mt={2} fontWeight={400}>
            By choosing a template, you'll gain access to its built in features and settings.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "70%",
          backgroundColor: '#FFF',
          borderTopLeftRadius: '50px'
        }}
      >
        <Box
          sx={{
            pt: 25,
            px: 15,
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <Box
            sx={{
              border: '1px solid #dbdbdb',
              width: '300px',
              borderRadius: '8px',
              mr: 2,
              cursor: 'pointer',
              '&:hover': {
                border: '1px solid #7430a1'
              }
            }}
          >
            <div
              style={{
                backgroundColor: '#E1BEE7',
                padding: "1 2.5 1 2.5",
                textAlign: 'center',
                borderTopRightRadius: "8px",
                borderTopLeftRadius: "8px"
              }}
            >
              <img src={require('../../../assets/images/grocery.png')} 
                width={150}
                height={150}
              />
            </div>
            <Box p={2.5}>
              <Typography variant="h4">
                Grocery store
              </Typography>
              <Typography variant="body1" color="text.secondary" mt={1.5} fontWeight={400}>
                A template that has the complete features of an online grocery app.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              border: '1px solid #dbdbdb',
              width: '300px',
              borderRadius: '8px',
              mr: 2,
              cursor: 'pointer',
              '&:hover': {
                border: '1px solid #7430a1'
              }
            }}
          >
            <div
              style={{
                backgroundColor: '#FEE4E2',
                padding: "1 2.5 1 2.5",
                textAlign: 'center',
                borderTopRightRadius: "8px",
                borderTopLeftRadius: "8px"
              }}
            >
              <img src={require('../../../assets/images/clothing.png')} 
                width={150}
                height={150}
              />
            </div>
            <Box p={2.5}>
              <Typography variant="h4">
                Clothing store
              </Typography>
              <Typography variant="body1" color="text.secondary" mt={1.5} fontWeight={400}>
                A template that has the complete features of an online clothing store.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SecondLayoutClip;