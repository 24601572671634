import React, { useState, FC, useRef } from "react";
import BannerImage from "assets/images/ecom.png";
import { useNavigate } from "react-router-dom";
import { Button, Typography, Divider, Dialog } from "@mui/material";
import Box from '@mui/material/Box';
import useDisplay from "contexts/Display";
import { styled } from '@mui/material/styles';
import Animate from "components/Animate";
import ThemeWrapper from "components/ThemeWrapper";
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styledFlexWrapper } from "styles";
import EditorDisplay from "components/Globals/EditorDisplay";
import useUser from "contexts/User";
import { useTheme } from '@mui/material/styles';

const MenuHome: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { displayMode } = useDisplay();
  const { userDetails } = useUser();
  const imageEl = useRef<HTMLImageElement>();
  const [lazyLoading, setLazyLoading] = useState<Boolean>(true);
  const [showDialog, setShowDialog] = useState(false);

  const handleImageLoad = () => {
    setLazyLoading(false);
  };

  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#175ef1' : '#175ef1',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));
  
  return (
    <Box
      sx={{
        ...styledFlexWrapper,
        background: '#FFF',
        // background: 'linear-gradient(180deg,transparent,hsla(0,0%,95%,.29) 47.39%,#fff 65%),linear-gradient(90deg,#f9ddf1 .07%,#e5e1ff 16.73%,#daedff 34.48%)',
        // backgroundImage: 'radial-gradient(145.05% 100% at 50% 0,rgb(243, 242, 255) 0,#f7f9fd 57.38%,#FFF 88.16%)',
        pb: 5,
        minHeight: "0"
      }}
    >
      <Box
        sx={{
          backgroundColor: '#FFF',
          px: 12,
          py: 3,
          display: 'flex',
          boxShadow: '0 2px 4px #33300014',
        }}
      >
        <Typography
          sx={{
            color: 'deepOrange.500',
            fontWeight: 800,
            fontSize: 32
          }}
        >
          GoBuild.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            pl: 6
          }}
        >
          <Box sx={{ display: 'flex', pt: 1.5 }}>
            <Typography>
              Pricing
            </Typography>
            <Typography ml={4}>
              Templates
            </Typography>
            <Typography ml={4}>
              Documentation
            </Typography>
            <Typography ml={4}>
              Get help
            </Typography>
          </Box>
          <Button
            variant="outlined"
          >
            Sign In
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          maxWidth: {
            xs: '100%',
            sm: '100%',
            md: '1300px',
            lg: '1300px'
          },
          margin: "auto"
        }}
      >
        <Animate type="fade-up" showOnScroll={false}>
          <Box
            sx={{
              display: 'flex',
              overflow: 'hidden',
              flexDirection: {
                xs: 'column',
                md: 'row'
              },
              justifyContent: 'space-between',
              borderRadius: 1,
              pt: 5,
              pb: 15
            }}
          >
            <Box 
              sx={{ 
                display: {
                  xs: 'block',
                  md: 'flex',
                  lg: 'flex'
                },
                '@media screen and (max-width: 900px)': {
                  mt: 7
                },
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '50%',
                  lg: '50%'
                },
                justifyContent: 'center', 
                alignItems: 'center'
              }}
            >
              <ThemeWrapper>
                <Typography
                  variant="h1"
                  sx={{
                    mb: 2,
                    fontSize: {
                      xs: 32,
                      sm: 32,
                      md: 46,
                      lg: 46
                    },
                    '@media screen and (max-width: 900px)': {
                      mt: 0,
                    },
                  }}
                >
                  Build your e-commerce business with us
                </Typography>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    fontWeight: 400,
                    color: '#2B2C33',
                    fontSize: {
                      xs: 18,
                      sm: 18,
                      md: 20,
                      lg: 20
                    },
                    width: {
                      xs: '100%',
                      sm: '100%',
                      md: '80%',
                      lg: '80%'
                    },
                  }}
                >
                  We can help you get started with your e-commerce venture. We offer many features including website creation, store manager, and more.
                </Typography>
                <Button
                  type="button"
                  color="primary"
                  disableElevation
                  variant="outlined"
                  onClick={() => {
                    // @ts-ignore
                    // if(localStorage.getItem("accessToken")) {
                    //   navigate('/');
                    // } else {
                    navigate('/login');
                    // }
                  }}
                  size="large"
                  sx={{
                    mt: 3,
                    fontWeight: 600,
                    textTransform: "none",
                    fontSize: 16
                  }}
                >
                  Learn more
                </Button>
                <Button
                  type="button"
                  color="primary"
                  disableElevation
                  variant="contained"
                  onClick={() => {
                    // @ts-ignore
                    // navigate('/second-layout');
                    setShowDialog(true)
                  }}
                  size="large"
                  sx={{
                    ml: 1.5,
                    mt: 3,
                    fontWeight: 600,
                    textTransform: "none",
                    fontSize: 16
                  }}
                >
                  Start Now
                </Button>
              </ThemeWrapper>
            </Box>
            
            {/* Banner Image */}
            <Box 
              sx={{ 
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '55%',
                  lg: '55%'
                },
                mt: {
                  xs: 8,
                  sm: 8,
                  md: 0,
                  lg: -9
                }
              }}
            >
              {lazyLoading && (
                <Box
                  sx={{
                    backgroundColor: 'transparent',
                    width: '900px',
                    height: '450px',
                    position: 'relative',
                    zIndex: 20
                  }}
                >
                </Box>
              )}
              <Box
                component="img"
                src={BannerImage}
                ref={imageEl}
                onLoad={handleImageLoad}
                sx={{
                  zIndex: 15,
                  position: 'relative',
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '100%',
                    lg: '100%'
                  },
                }}
              ></Box>
            </Box>
            
            {showDialog && (
              <Dialog 
                open={true}
                PaperProps={{
                  style: {
                    minWidth: "700px",
                    overflow: 'hidden'
                  }
                }}
                onClose={() => setShowDialog(false)}
              >
                <Box
                  sx={{
                    padding: "20px 30px 60px 30px",
                  }}
                >
                  <Box>
                    <Typography variant="h2" mt={2}>
                      Appearance
                    </Typography>
                    <Typography variant="body1" mt={1} sx={{ width: "70%" }} color="text.secondary" fontWeight={400}>
                      Change how you wanted the UI to look in the browser.
                    </Typography>
                  </Box>
                  <Divider sx={{ my: 3 }} />
                  <Box>
                    <Typography>
                      Interface Theme
                    </Typography>
                    <Typography color="text.secondary" variant="body2" fontWeight={400}>
                      Select your preferred theme
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      pt: 2.7,
                      display: 'flex',
                    }}
                  >
                    <Box
                      sx={{
                        width: '200px',
                        mr: 3
                      }}
                    >
                      <img 
                        src={require('../../../assets/images/theme1.png')} 
                        style={{
                          width: "100%"
                        }}
                      />
                      <Typography sx={{ mt: 0.7 }}>
                        System preference
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: '200px',
                        mr: 3
                      }}
                    >
                      <img 
                        src={require('../../../assets/images/theme2.png')} 
                        style={{
                          width: "100%"
                        }}
                      />
                      <Typography sx={{ mt: 0.7 }}>
                        Light
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: '200px',
                      }}
                    >
                      <img 
                        src={require('../../../assets/images/theme3.png')} 
                        style={{
                          width: "100%"
                        }}
                      />
                      <Typography sx={{ mt: 0.7 }}>
                        Dark
                      </Typography>
                    </Box>
                  </Box>
                  <Divider sx={{ my: 3 }} />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Box>
                      <Typography>
                        Transparency
                      </Typography>
                      <Typography color="text.secondary" variant="body2" fontWeight={400}>
                        Make the desktop transparent
                      </Typography>
                    </Box>
                    <IOSSwitch sx={{ m: 1 }} defaultChecked />
                  </Box>
                </Box>

                <Divider />
                <Box
                  sx={{
                    width: "100%",
                    mt: 3,
                    pb: 3,
                    bottom: 0,
                    left: 0,
                    overflow: 'hidden'
                  }}
                >
                  <Box
                    sx={{
                      ml: 4,
                      width: '92.5%'
                    }}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        width: '49%',
                        height: "48px",
                        border: '1px solid rgba(0, 0, 0, 0.12)'
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        width: '49%',
                        height: "48px",
                        backgroundColor: '#175ef1',
                        color: '#FFF',
                        fontWeight: 400,
                        ml: 1
                      }}
                    >
                      Save changes
                    </Button>
                  </Box>
                </Box>
              </Dialog>
            )}
          </Box>
        </Animate>
      </Box>
    </Box>
  )
}

export default MenuHome;