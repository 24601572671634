import React from "react"
import { CircularProgress, Typography, Button, Box } from "@mui/material"
import GoogleIcon from "../Icons/GoogleIcon"

interface Props {
  loading: boolean;
}

const GoogleBtn: React.FC<Props> = ({ loading }) => {
  return (
    <Button
      id="google-btn"
      sx={{
        borderRadius: 1,
        py: '0.6rem',
        columnGap: '1rem',
        height: '50px',
        textDecoration: 'none',
        width: '100%',
        color: '#393e45',
        cursor: 'pointer',
        fontWeight: 500,
        border: '1px solid #d9d9d9',
        boxShadow: '0 1px 3px #3330000d, 0 1px 1px #33300006',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {!loading ? (
        <>
          <GoogleIcon />
          <Typography variant="subtitle1" sx={{ 
            margin: '0 0.9em 0em 0',
            float: 'right'
          }}>
            Continue with Google
          </Typography>
        </>
      ) : ( 
        <CircularProgress 
          size={15} color="inherit" 
          style={{
            margin: "auto"
          }}
        />
      )}
    </Button>
  )
}

export default GoogleBtn