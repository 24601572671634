import React, { FC, useEffect } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Typography } from "@mui/material";

const GithubAccess: FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    invokeGithubTokenApi();
  }, []);

  // @NOTE: this api is calling twice
  const invokeGithubTokenApi = async () => {
    try {
      // API call
      const res = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_LOCALHOST}/users/v1/github/login`,
        data: {
          code: searchParams.get("code")
        },
        headers: {
          "Accept": "application/json"
        }
      });

      // Response
      if(res.data.data.success) {
        const data = res.data.data.data
        localStorage.setItem("accessToken", data.accessToken);
        localStorage.setItem("userId", data.userId);
        localStorage.setItem("profileImage", data.profileImage);
        localStorage.setItem("firstName", data.firstName);
        localStorage.setItem("lastName", data.lastName);
        navigate("/");
      }
    } catch(err) {
      if(err) {
        navigate("/login");
      }
    }
  }

  return (
    <Typography sx={{ p: 1 }} variant="body2">
      Verifying github account..
    </Typography>
  );
};

export default GithubAccess;