import { Navigate, Outlet } from "react-router-dom"
import useUser from "contexts/User"
import React, { useEffect, useState } from "react"

const useAuth = () => {
  const { checkToken, setLoadingPage } = useUser();
  const [isAuth, setIsAuth] = useState<boolean | null>(true);

  useEffect(() => {
    // remove temporarily since there's no check token functionality yet
    // checkTokenTrigger();
  }, []);

  const checkTokenTrigger = async () => {
    setLoadingPage(true);
    try {
      setIsAuth(true);
      const res = await checkToken();
      localStorage.setItem("membershipDetails", res.data.data.data.user.membershipDetails);
      if (res.data.data.success) {
        setIsAuth(true)
      } else {
        setIsAuth(false);
      }
    } catch (err) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("lastName");
      localStorage.removeItem("profileImage");
      localStorage.removeItem("firstName");
      localStorage.removeItem("userId");
      localStorage.removeItem("membershipDetails");
      setIsAuth(false);
    } finally {
      setLoadingPage(false);
    }
  };

  return isAuth
}

const AuthenticatedRoutes: React.FC<any> = ({ children }) => {
  const isAuth = useAuth()
  if (isAuth == null) { // waiting
    return null;
  }
  return isAuth ? <Outlet/> : <Navigate to="/" />;
}

export default AuthenticatedRoutes