import React, { FC } from "react";
import { Box, Button, Typography, Card, Chip, Divider, CircularProgress, ListItem, ListItemIcon } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import useUser from "contexts/User";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const PricingSection: FC = () => {
  const navigate = useNavigate();
  const { userDetails, updateAuditLog } = useUser();
  
  return (
    <Box
      sx={{
        px: {
          xs: 2,
          sm: 2,
          md: 0,
          lg: 0
        },
      }}
    >
      <Box sx={{ textAlign: 'center', mt: 6 }}>
        <Typography variant="h1">
          Learn more with us
        </Typography>
      </Box>
      <Box 
        sx={{
          display: 'flex', 
          mx: 'auto', 
          width: {
            xs: '100%',
            sm: '100%',
            md: '1000px',
            lg: '1000px'
          }, 
          mt: 5,
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row'
          },
        }}
      >
        <Card 
          sx={{ 
            mr: 2, 
            width: {
              xs: '100%',
              sm: '100%',
              md: '400px',
              lg: '400px'
            }
          }}
        >
          <Box 
            sx={{ 
              p: 2,
              borderBottomStyle: 'solid',
              borderBottomWidth: '1px',
              borderBottomColor: 'grey.200'
            }}
          >
            <Typography variant="h4">
              Basic
            </Typography>
          </Box>
          <Box>
            <Box sx={{ pt: 3, px: 2, textAlign: 'center' }}>
              <Typography variant="h1" sx={{ fontSize: '38px' }}>
                $0
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Always free
              </Typography>
              <Button 
                variant="outlined" 
                sx={{ mt: 2 }}
                onClick={() => {
                  updateAuditLog({
                    field: "basic_plan_visit_cnt"
                  });
                  if(userDetails.user?.accessToken) {
                    navigate('/coming-soon');
                  } else {
                    navigate('/login?from=free');
                  }
                }}
              >
                Sign up
              </Button>
              <Divider sx={{ my: 3, borderStyle: 'dashed' }} />
            </Box>
            <ListItem>
              <ListItemIcon sx={{ minWidth: '33px' }}>
                <CheckCircleIcon sx={{ color: 'green.500' }} />
              </ListItemIcon>
              <Typography variant="body2">
                Start learning with our free test
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: '33px' }}>
                <CheckCircleIcon sx={{ color: 'green.500' }} />
              </ListItemIcon>
              <Typography variant="body2">
                Free test result breakdown
              </Typography>
            </ListItem>
          </Box>
        </Card>
        <Card
          sx={{ 
            mr: 2, 
            width: {
              xs: '100%',
              sm: '100%',
              md: '400px',
              lg: '400px'
            },
            mt: {
              xs: 3,
              sm: 3,
              md: 0,
              lg: 0
            }
          }}
        >
          <Box 
            sx={{ 
              p: 2,
              borderBottomStyle: 'solid',
              borderBottomWidth: '1px',
              backgroundColor: 'purple.50',
              borderBottomColor: 'grey.200',
              display: 'flex',
              justifyContent: 'space-between',
              color: 'purple.900'
            }}
          >
            <Typography variant="h4">
              Pro
            </Typography>
            <Chip 
              label="Recommended" 
              sx={{
                backgroundColor: 'purple.900',
                color: '#FFF',
                height: '25px',
                fontWeight: 600,
                letterSpacing: 1
              }}
            />
          </Box>
          <Box sx={{ textAlign: 'center', p: 3 }}>
            <Typography variant="h1" sx={{ fontSize: '38px' }}>
              $40
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              One-time payment
            </Typography>
            <Button 
              variant="outlined" 
              sx={{ mt: 2 }}
              onClick={() => {
                updateAuditLog({
                  field: "pro_plan_visit_cnt"
                });
                if(userDetails.user?.accessToken) {
                  navigate('/coming-soon');
                } else {
                  navigate('/login');
                }
              }}
            >
              Get now
            </Button>
            <Divider sx={{ my: 3, borderStyle: 'dashed' }} />
            <ListItem>
              <ListItemIcon sx={{ minWidth: '33px' }}>
                <CheckCircleIcon sx={{ color: 'green.500' }} />
              </ListItemIcon>
              <Typography variant="body2">
                Dedicated learning materials
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: '33px' }}>
                <CheckCircleIcon sx={{ color: 'green.500' }} />
              </ListItemIcon>
              <Typography variant="body2">
                Up to 70 interactive tests
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: '33px' }}>
                <CheckCircleIcon sx={{ color: 'green.500' }} />
              </ListItemIcon>
              <Typography variant="body2">
                Test results feedback
              </Typography>
            </ListItem>
            <ListItem sx={{ alignItems: 'start' }}>
              <ListItemIcon sx={{ minWidth: '33px' }}>
                <CheckCircleIcon sx={{ color: 'green.500' }} />
              </ListItemIcon>
              <Typography variant="body2">
                Advanced UX lessons
              </Typography>
            </ListItem>
            <ListItem sx={{ alignItems: 'start' }}>
              <ListItemIcon sx={{ minWidth: '33px' }}>
                <CheckCircleIcon sx={{ color: 'green.500' }} />
              </ListItemIcon>
              <Typography variant="body2">
                Lifetime pro member
              </Typography>
            </ListItem>
          </Box>
        </Card>
        <Card 
          sx={{ 
            width: {
              xs: '100%',
              sm: '100%',
              md: '400px',
              lg: '400px'
            },
            mt: {
              xs: 3,
              sm: 3,
              md: 0,
              lg: 0
            }
          }}
        >
          <Box
            sx={{ 
              p: 2,
              background: 'linear-gradient(480deg,#7928CA,#FF0080)',
            }}
          >
            <Typography variant="h4"
              sx={{
                color: 'common.white'
              }}
            >
              Enterprise
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'center', p: 3 }}>
            <Typography variant="h1" sx={{ fontSize: '38px' }}>
              $100
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              One-time payment
            </Typography>
            <Button 
              onClick={() => {
                updateAuditLog({
                  field: "enterprise_plan_visit_cnt"
                });
                if(userDetails.user?.accessToken) {
                  navigate('/coming-soon');
                } else {
                  navigate('/login');
                }
              }}
              sx={{ 
                mt: 2,
                px: 2,
                background: 'linear-gradient(140deg,#7928CA,#FF0080)',
                color: '#FFF'
              }}
            >
              Get now
            </Button>
            <Divider sx={{ my: 3 }} />
            <ListItem>
              <ListItemIcon sx={{ minWidth: '33px' }}>
                <CheckCircleIcon sx={{ color: 'green.500' }} />
              </ListItemIcon>
              <Typography variant="body2">
                Priority support for your team
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: '33px' }}>
                <CheckCircleIcon sx={{ color: 'green.500' }} />
              </ListItemIcon>
              <Typography variant="body2">
                Up to 130 interactive tests
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: '33px' }}>
                <CheckCircleIcon sx={{ color: 'green.500' }} />
              </ListItemIcon>
              <Typography variant="body2">
                Review results with your team
              </Typography>
            </ListItem>
            <ListItem sx={{ alignItems: 'start' }}>
              <ListItemIcon sx={{ minWidth: '33px' }}>
                <CheckCircleIcon sx={{ color: 'green.500' }} />
              </ListItemIcon>
              <Typography variant="body2">
                Advanced test result breakdown
              </Typography>
            </ListItem>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default PricingSection;