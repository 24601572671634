import { Avatar, Box, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useUser from "../../../contexts/User";

const UserDropdown: React.FC = () => {
  let navigate = useNavigate();
  const { userDetails, logoutUser, loadingPage } = useUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const onClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    setAnchorEl(null);
    try {
      navigate("/");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("paths");
      localStorage.removeItem("profileImage");
      localStorage.removeItem("userId");
    } catch (err) {
      console.log(err);
    }
  };

  const goToProfile = () => {
    navigate(`/profile/${userDetails.profile._id}`);
    handleClose();
  };

  return (
    <div className="FlexNowrap nav-items">
      <button
        className="IconBtn"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={onClick}
      >
        <Avatar
          //@ts-ignore
          src={localStorage.getItem("profileImage")}
          style={{
            borderRadius: "100%",
            height: 35,
            width: 35,
          }}
        />
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box className="user-dropdown">
          {/* <ListItem 
            sx={{ px: 2.5 }}
          >
            <ProfileDisplay
              // @ts-ignore
              imagePath={localStorage.getItem("profileImage")}
              imageWidth={35}
              imageHeight={35}
              // name={userDetails.profile.firstName}
              // @ts-ignore
              name={localStorage.getItem("firstName")}
              label="Mentor"
              description=""
            />
          </ListItem> */}
          {/* <Divider sx={{ my: 1 }} /> */}
          <MenuItem
            onClick={() => handleLogout()}
            sx={{
              fontSize: "16px",
              color: "text.secondary",
              px: 2.5,
            }}
          >
            Log Out
          </MenuItem>
        </Box>
      </Menu>
    </div>
  );
};

export default UserDropdown;
